import { radiusS } from '@ingka/variables'
import { Box, Group, Stack } from '@mantine/core'

import { BrandColour, spacing25, spacing50 } from 'styles/tokens/insikt.tokens'

import { Paragraph, SubHeading } from 'components/primitives/Text'

/**
 * UI component that displays a number and a text with a yellow background.
 * @param count - The number to display.
 * @param text - The text to display.
 */

type NumberTagProps = {
  count: number | React.ReactNode
  text?: string | React.ReactNode
  subtitle?: string
}
const NumberTag: React.FC<NumberTagProps> = ({ count, text, subtitle }) => {
  return (
    <Stack gap={spacing50} px={spacing25} mb={spacing25}>
      <Group gap={spacing25} align="flex-start">
        <Box
          bg={BrandColour.Yellow}
          px={spacing25}
          style={{ borderRadius: radiusS }}
        >
          {typeof count === 'number' ? <SubHeading>{count}</SubHeading> : count}
        </Box>
        {typeof text === 'string' ? <SubHeading>{text}</SubHeading> : text}
      </Group>
      {subtitle && <Paragraph>{subtitle}</Paragraph>}
    </Stack>
  )
}

export default NumberTag
