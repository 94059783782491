import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const AOVInfoBody = () => {
  const { t } = useTranslation()
  return (
    <>
      <Flex direction={'column'} gap={space50}>
        <Text headingSize="m">{t('features.1-efficiency.acv-info.what-is-acv')}</Text>

        <Text bodySize="s">{t('features.1-efficiency.acv-info.average-customer-value')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.whether-one-order-multiple-orders-1')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.metric-reflects-ability-to-boost')}
            </Text>
          </li>
        </ul>

        <Text headingSize="s">{t('features.1-efficiency.acv-info.how-is-acv-measured')}</Text>
        <Text bodySize="s">{t('features.1-efficiency.acv-info.minimum-volume-requirement')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.ensure-accurate-acv-measurement')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.acv-info.low-data-volume-warning')}</Text>
          </li>
        </ul>

        <Text headingSize="s">
          {t('features.1-efficiency.acv-info.why-focus-on-individual-skills')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.acv-calculated-specific-skills')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.calculation-considers-inbound-contacts')}
            </Text>
          </li>
        </ul>

        <Text headingSize="s">{t('features.1-efficiency.acv-info.benchmarks')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.average-customer-value-across-coworkers')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.highest-average-customer-value')}
            </Text>
          </li>
        </ul>

        <Text headingSize="s">{t('features.1-efficiency.acv-info.trends')}</Text>

        <Text headingSize="xs">
          {t('features.1-efficiency.acv-info.long-term-performance-view')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.acv-trends-provide-weekly-breakdown')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">
          {t('features.1-efficiency.acv-info.interpreting-fluctuations')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.large-weekly-variations-insufficient-data')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.hover-over-trend-view-interactions')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.acv-info.why-weekly-trends-matter')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.acv-info.weekly-data-offers-consistent-insights')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.acv-info.key-takeaway')}</Text>
      </Flex>
    </>
  )
}

export default AOVInfoBody
