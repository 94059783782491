import { CoworkerDataDocument } from 'lib/types/coworkerData.types'

import { apiSlice } from 'state/slices/api/api.slice'

export const coworkerDataSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCoworkerData: builder.query<CoworkerDataDocument, string>({
      query: (coworkerId) => `cw/coworkers/${coworkerId}/coworker-data`,
      providesTags: (result, error, coworkerId: string) => [
        { type: 'CoworkerData', id: coworkerId },
      ],
    }),
  }),
})

export const { useGetCoworkerDataQuery: useCoworkerData } = coworkerDataSlice
