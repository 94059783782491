import { colourSecondary } from 'styles/tokens/insikt.tokens'

import styled from 'styled-components'

type NotificationsCounterProps = {
  count: number
} & React.HTMLAttributes<HTMLDivElement>

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  top: -4px;
  background-color: ${colourSecondary.dark};
  width: 1rem;
  height: 1rem;
  font-size: 0.66rem;
  pointer-events: none;
  border-radius: 50%;
`

const NotificationsCounter: React.FC<NotificationsCounterProps> = ({
  count,
  ...htmlAttributes
}) => {
  return <Wrapper {...htmlAttributes}>{count}</Wrapper>
}

export default NotificationsCounter
