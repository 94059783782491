import Text from '@ingka/text'
import { Flex } from '@mantine/core'

import { EmptyContent } from 'components/composites/Shared/EmptyContent'

const EmptyPanel = ({ title }: { title: string }) => {
  return (
    <Flex direction={'column'}>
      <Text headingSize="s">{title}</Text>
      <EmptyContent translationKey="features.1-efficiency.no-data-available" />
    </Flex>
  )
}

export default EmptyPanel
