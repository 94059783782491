import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { AverageHandleTime, Rolling30AHT } from 'lib/types/coworkerData.types'
import { displayTime } from 'lib/utils/displayTime.utils'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

interface AhtGraphProps {
  data: Rolling30AHT
}

const AhtGraph = ({ data }: AhtGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="90%">
      <BarChart width={500} height={300} data={[data]}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis width={100} unit={' sec.'} />
        <Tooltip />
        <Legend />
        <Bar dataKey="avgTalkTime" name={'Avg. talk time'} stackId="a" fill={'#489CE3'} />
        <Bar dataKey="avgHoldTime" name={'Avg. hold time'} stackId="a" fill={'#a3a3a4'} />
        <Bar dataKey="avgAcwTime" name={'Avg. ACW time'} stackId="a" fill={'#003E72'} />
      </BarChart>
    </ResponsiveContainer>
  )
}

interface AhtLineGraphProps {
  data: AverageHandleTime
}

export const AhtLineGraph = ({ data }: AhtLineGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={500} height={300} data={data.ahtTrendWeek}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={'period'} />
        <YAxis
          width={100}
          tickFormatter={(value) => {
            return `${displayTime(value)}`
          }}
        ></YAxis>
        <Tooltip
          content={({ active, payload, label }: any) => {
            if (active && payload && payload.length) {
              return (
                <Flex miw={200} bg={backgroundColourDefault} direction={'column'} p={space50}>
                  <Text headingSize="s">{label}</Text>
                  <Text>{`Avg. AHT: ${displayTime(
                    payload[0].value + payload[1].value + payload[2].value
                  )}`}</Text>
                  <Text>{`Avg. talk time: ${displayTime(payload[0].value)}`}</Text>
                  <Text>{`Avg. hold time: ${displayTime(payload[1].value)}`}</Text>
                  <Text>{`Avg. ACW time: ${displayTime(payload[2].value)}`}</Text>
                  <Text>{`Handled volume: ${payload[0].payload.volume}`}</Text>
                </Flex>
              )
            }
          }}
        />

        <Area
          dataKey={'avgTalkTime'}
          name={'Average talk time'}
          stackId="a"
          fill={'#009985'}
          stroke={'#009985'}
        />
        <Area
          dataKey={'avgHoldTime'}
          name={'Average hold time'}
          stackId="a"
          fill={'#A3ACB0'}
          stroke={'#A3ACB0'}
        />
        <Area
          dataKey={'avgAcwTime'}
          name={'Average ACW time'}
          stackId="a"
          fill={'#008ca4e2'}
          stroke={'#008ca4e2'}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default AhtGraph
