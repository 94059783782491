import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

/** Returns a label for the coworker depending on whether they are in the
 *  co-worker section ("You") or elsewhere (the co-workers name) */
export const useCoworkerLabel = (coworker: Coworker | undefined) => {
  const { t } = useTranslation()
  const pageType = useCurrentPageType()
  if (pageType === 'coworker') {
    return t('common.you')
  }
  return coworker?.firstName
}
