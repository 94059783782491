import { collection, collectionGroup, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { firestore } from 'config/firebase/firebase.config'

import { CoachingSession } from 'lib/types/coachingSession.types'
import { CustomerComment } from 'lib/types/customerComment.types'
import { Mission } from 'lib/types/mission.types'
import { User } from 'lib/types/user.types'

export const useAdminStats = () => {
  // Coaching sessions stats
  const [coachingSessions, setCoachingSessions] = useState<CoachingSession[]>()

  // Missions stats
  const [missions, setMissions] = useState<Mission[]>()

  // Customer comments stats
  const [comments, setComments] = useState<CustomerComment[]>()

  // Users stats
  const [users, setUsers] = useState<User[]>()
  useEffect(() => {
    const unsubscribeUsers = onSnapshot(collection(firestore, `users`), (snapshot) => {
      const usersData = snapshot.docs.map((doc) => doc.data()) as User[]
      setUsers(usersData)
    })

    const unsubscribeCoachingSessions = onSnapshot(
      collectionGroup(firestore, `coachingSessions`),
      (snapshot) => {
        const coachingSessionsData = snapshot.docs.map((doc) => doc.data()) as CoachingSession[]
        setCoachingSessions(coachingSessionsData)
      }
    )

    const unsubscribeMissions = onSnapshot(collectionGroup(firestore, `missions`), (snapshot) => {
      const missionsData = snapshot.docs.map((doc) => doc.data()) as Mission[]
      setMissions(missionsData)
    })

    const unsubscribeComments = onSnapshot(
      collectionGroup(firestore, `coworkerCustomerComments`),
      (snapshot) => {
        let commentsData: CustomerComment[] = []
        snapshot.docs.forEach((doc) =>
          doc.data().comments.forEach((comment: CustomerComment) => commentsData.push(comment))
        )

        setComments(commentsData)
      }
    )

    // Clean up the listener when the component unmounts)
    return () => {
      unsubscribeCoachingSessions()
      unsubscribeMissions()
      unsubscribeUsers()
      unsubscribeComments()
    }
  }, [])

  return {
    coachingSessions,
    missions,
    users,
    comments,
  }
}
