import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { AOV } from 'lib/types/coworkerData.types'
import { formatMoney } from 'lib/utils/formatMoney.utils'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

interface AovGraphProps {
  data: AOV
}

const AovGraph = ({ data }: AovGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={500} height={300} data={data.aovTrendWeek}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={'period'} />
        <YAxis
          unit={''}
          width={100}
          tickFormatter={(tick) => {
            return formatMoney(tick)
          }}
        />
        <Tooltip
          content={({ active, payload, label }: any) => {
            if (active && payload && payload.length) {
              return (
                <Flex miw={200} bg={backgroundColourDefault} direction={'column'} p={space50}>
                  <Text headingSize="s">{label}</Text>
                  <Text>{`Avg. ACV: ${formatMoney(payload[0].value)}`}</Text>
                  <Text>{`Handled volume: ${payload[0].payload.visitors}`}</Text>
                </Flex>
              )
            }
          }}
        />

        <Area dataKey="aov" name="AOV value" fill={'#EE8310'} stroke={'#EE8310'} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default AovGraph
