import Skeleton from '@ingka/skeleton'
import { space50 } from '@ingka/variables'
import { Box, Stack } from '@mantine/core'

import { getSkeletonWidth } from 'lib/utils/skeletons.helper'

import { Widget } from 'components/composites/Shared/Widget'

const CoachingSessionPreviewSkeleton: React.FC = () => (
  <Widget>
    <Stack>
      <Box mb={space50}>
        <Skeleton width={getSkeletonWidth(20, 30, 'ch')} />
      </Box>
      <Skeleton height="1rem" width={getSkeletonWidth(80, 100, '%')} />
      <Skeleton height="1rem" width={getSkeletonWidth(80, 100, '%')} />
      <Skeleton height="1rem" width={getSkeletonWidth(80, 100, '%')} />
      <Skeleton height="1rem" width={getSkeletonWidth(80, 100, '%')} />
      <Skeleton height="1rem" width={getSkeletonWidth(80, 100, '%')} />
    </Stack>
  </Widget>
)

export default CoachingSessionPreviewSkeleton
