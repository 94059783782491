import { colourNeutralGrey300 } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

export const Dot = styled.span<{ color?: string }>`
  width: 0.4em;
  height: 0.4em;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.45em;
  border-color: ${({ color }) => color ?? colourNeutralGrey300};
`
