import { useFeatureFlag } from './FeatureToggle.hooks'

type FeatureToggleProps = React.PropsWithChildren<{
  /** A Firebase Remote Config parameter key identifying a feature to toggle on or off. */
  featureKey: string
  /** A component that is rendered if the parameterKey is set to false. Defaults to null. */
  fallbackComponent?: React.ReactNode
}>

const FeatureToggle: React.FC<FeatureToggleProps> = ({
  featureKey,
  children,
  fallbackComponent = null,
}) => {
  const featureFlagValue = useFeatureFlag(featureKey)

  const renderedNode = featureFlagValue ? children : fallbackComponent
  return <>{renderedNode}</>
}

export default FeatureToggle
