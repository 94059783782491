import { space200 } from '@ingka/variables'

import styled from 'styled-components'

type SpacerProps = {
  height?: string
}

export const Spacer = styled.div<SpacerProps>`
  min-height: ${(props) => props.height || '70vh'};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${space200};
`
