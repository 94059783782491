import Button from '@ingka/button'
import { space100, space200 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useUser } from 'hooks/users'

import { useSyncUserInfo } from 'state/slices/api'

import { FullscreenCenter } from 'components/layout/FullScreenCenter'
import { Paragraph } from 'components/primitives/Text'

const GuestPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: user } = useUser()
  const [syncUserInfo, { isLoading: isLoadingSyncUserInfo }] = useSyncUserInfo()
  const handleSyncInfoClicked = useCallback(() => {
    if (!user) return
    syncUserInfo(user.id).then(() => window.location.reload())
  }, [syncUserInfo, user])

  useEffect(() => {
    if (user?.role !== 'guest') {
      navigate('/')
    }
  }, [user, navigate])

  return (
    <>
      <FullscreenCenter>
        <Paragraph>
          {t('pages.guest.message-one')}
          <br />
          <br />
          <Trans i18nKey="pages.guest.message-two">
            If you believe this is an error, please reach out to us on
            <a
              target="_blank"
              href="https://teams.microsoft.com/l/channel/19%3AS6S2HV4iOzXH-WNgN9BnLxWk3QgKKRWU1EkUOnjr5wA1%40thread.tacv2/General?groupId=0c87ea0e-fccd-4f47-b846-ff214e7bd842&tenantId=720b637a-655a-40cf-816a-f22f40755c2c"
              rel="noreferrer"
            >
              Teams
            </a>
            .
          </Trans>
        </Paragraph>
        <Flex align={'center'} gap={space100} justify={'center'} w={'100%'} mt={space200}>
          <Paragraph>{t('pages.guest.message-three')}</Paragraph>
          <Button
            data-testid="btn-sync-info"
            small
            type="secondary"
            onClick={handleSyncInfoClicked}
            loading={isLoadingSyncUserInfo}
          >
            {t('navigation.sidebar-right.sync-info')}
          </Button>
        </Flex>
      </FullscreenCenter>
    </>
  )
}

export default GuestPage
