import 'fast-text-encoding'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from 'firebase/auth'

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASEURL,
  prepareHeaders: async (headers) => {
    const auth = getAuth()
    const idToken = await auth.currentUser?.getIdToken()

    headers.set('Authorization', `Bearer ${idToken}`)
  },
})

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    'Coworker',
    'Customer Comment',
    'Team',
    'User',
    'UserFixtures',
    'Prefs',
    'Country',
    'Metric',
    'Interaction',
    'Mission',
    'Coaching Session',
    'PowerBi',
    'QualityAssessment',
    'SurveyMetric',
    'Game',
    'Translations',
    'CoworkerData',
  ],
  endpoints: () => ({}),
})

export const invalidateTags = apiSlice.util.invalidateTags

export const { middleware } = apiSlice
export default apiSlice.reducer
