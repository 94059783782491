import { Grid } from '@mantine/core'

import { Widget } from 'components/composites/Shared/Widget'

interface Props {
  leftPanel: React.ReactNode
  rightPanel: React.ReactNode
}

const SubPanelLayout = ({ leftPanel, rightPanel }: Props) => {
  return (
    <Grid>
      <Grid.Col span={4}>
        <Widget style={{ height: '100%', overflow: 'hidden' }} border>
          {leftPanel}
        </Widget>
      </Grid.Col>
      <Grid.Col span={8} h={'100%'}>
        <Widget style={{ height: '100%', overflow: 'hidden' }} border>
          {rightPanel}
        </Widget>
      </Grid.Col>
    </Grid>
  )
}

export default SubPanelLayout
