import Pill from '@ingka/pill'
import { Flex } from '@mantine/core'
import React from 'react'

import { spacing25, spacing75 } from 'styles/tokens/insikt.tokens'

import { Label, SubHeading, Subtitle } from '../Text'

/** Header component for coaching session flow with step counter */
type NumberedHeaderProps = {
  /** If set, creates a heading with the provided text content. */
  title?: React.ReactNode | string
  /** If set, creates a subtitle with the provided text content. */
  subtitle?: React.ReactNode | string
  /** If set, creates a circled pill with the provided count/number step. */
  step?: string | number
} & React.HTMLAttributes<HTMLDivElement>

const NumberedHeader: React.FC<NumberedHeaderProps> = ({ title, subtitle, step }) => {
  return (
    <>
      <Flex justify="flex-start" gap={spacing75} align="center">
        {step && (
          <Pill
            size="xsmall"
            style={{
              backgroundColor: 'black',
              borderRadius: '100%',
              height: '30px',
              width: '30px',
            }}
            label={<SubHeading style={{ color: 'white' }}>{step}</SubHeading>}
          ></Pill>
        )}
        {title && (
          <Subtitle data-testid="numbered-header-title" style={{ marginBottom: spacing25 }}>
            {title}
          </Subtitle>
        )}
      </Flex>
      <Flex>{subtitle && <Label data-testid="numbered-header-subtitle">{subtitle}</Label>}</Flex>
    </>
  )
}

export default NumberedHeader
