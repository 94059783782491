import SkapaButton from '@ingka/button'
import { radiusL, space100 } from '@ingka/variables'

import styled from 'styled-components'

export const Background = styled.div`
  background-color: #fff;
  border-radius: ${radiusL};
  padding: ${space100};
  &:hover {
    background-color: #f5f5f5;
  }
`

export const Button = styled(SkapaButton)<{ read?: boolean }>`
  text-align: left;
  font-weight: ${(props) => (props.read ? 'normal' : 'bold')};

  &:hover {
    text-decoration: underline;
  }

  .btn__inner {
    min-height: 0;
    padding: 0;
  }
`
