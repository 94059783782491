import Button from '@ingka/button'
import { space25 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useUser } from 'hooks/users'

import { useSetCoworkerRoleMutation } from 'state/slices/api'

import { Stack } from 'components/layout/Stack'
import { Paragraph } from 'components/primitives/Text'

import * as Styled from './WelcomeModal.styling'

type Props = {
  onRoleSelected: () => void
}

const CoworkerContentRoleSelection: React.FC<Props> = ({ onRoleSelected }) => {
  const { t } = useTranslation()

  const { data: user } = useUser()
  const [setCoworkerRole] = useSetCoworkerRoleMutation()

  const handleRoleSelected = (role: string) => {
    if (!user) return
    setCoworkerRole({
      coworkerId: user.legacyId,
      role,
    })
    onRoleSelected()
  }

  return (
    <Stack>
      <Paragraph>
        <Styled.Paragraph>
          {t('introduction.welcome-modal.coworker-content.select-role-1')}
        </Styled.Paragraph>
      </Paragraph>

      <Flex align="center" gap={space25}>
        <Button type="primary" onClick={() => handleRoleSelected('sales')}>
          {'Sales'}
        </Button>
        <Button type="primary" onClick={() => handleRoleSelected('resolution')}>
          {'Resolution'}
        </Button>
        <Button type="primary" onClick={() => handleRoleSelected('both')}>
          {t('common.roles.both')}
        </Button>
      </Flex>

      <Paragraph>
        <Styled.Paragraph>
          {t('introduction.welcome-modal.coworker-content.select-role-hint')}
        </Styled.Paragraph>
      </Paragraph>
    </Stack>
  )
}

export default CoworkerContentRoleSelection
