import { space200, space300 } from '@ingka/variables'

import styled from 'styled-components'

export const SignOutSection = styled.div`
  margin-top: ${space200};
  align-self: center;
`

export const Section = styled.div`
  padding-bottom: ${space300};
  display: flex;
  flex-direction: column;
`
