import List from '@ingka/list'
import { Flex } from '@mantine/core'
import { Trans, useTranslation } from 'react-i18next'

import { Paragraph } from 'components/primitives/Text'
import { SubTitleBlue } from 'components/primitives/Text/Text.component'

import * as Styled from './WelcomeModal.styling'

const NUMBER_OF_STEPS = 4

const TeamleadWelcomeModalContent = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Flex align={'center'} direction="column">
        <SubTitleBlue>{t('introduction.welcome-modal.title')}</SubTitleBlue>
      </Flex>
      <Styled.ModalBodyImage src="/images/party-popper.gif" />
      <Paragraph>
        <Styled.Paragraph>
          {t('introduction.welcome-modal.teamlead-content.app-goal')}
        </Styled.Paragraph>

        <Styled.Paragraph>
          <strong>{t('introduction.welcome-modal.teamlead-content.getting-started')}</strong>
        </Styled.Paragraph>

        <List>
          {[...Array(NUMBER_OF_STEPS).keys()].map((step) => (
            <li key={step}>
              <Trans
                i18nKey={`introduction.welcome-modal.teamlead-content.getting-started-${step}`}
              />
            </li>
          ))}
        </List>

        <Styled.Paragraph>
          <Trans i18nKey="introduction.welcome-modal.feedback">
            If you have a great idea for Insikt or think that something is missing or not working,
            reach out to us on
            <a
              href="https://teams.microsoft.com/l/team/19%3aRQ5meDGPGb_a4y_-xzZU0lsjHWePLYPMEku8dQLjK-41%40thread.tacv2/conversations?groupId=152e29cf-423f-4115-b34d-e7ddf18e96ae&tenantId=720b637a-655a-40cf-816a-f22f40755c2c"
              target="_blank"
              rel="noreferrer"
            >
              Teams
            </a>
            !
          </Trans>
        </Styled.Paragraph>
      </Paragraph>
    </div>
  )
}

export default TeamleadWelcomeModalContent
