import FormField from '@ingka/form-field'
import { breakpointL, space100 } from '@ingka/variables'

import styled from 'styled-components'

export const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space100};
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpointL}) {
    gap: ${space100};
    flex-direction: row;
  }
`

export const MetricField = styled(FormField)`
  width: 100%;

  @media (min-width: ${breakpointL}) {
    width: 16rem;
  }
`

export const DateField = styled(FormField)`
  width: 100%;

  @media (min-width: ${breakpointL}) {
    width: 10rem;
  }
`
