import Pill from '@ingka/pill'
import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'

import { BrandColour, spacing50, spacing100 } from 'styles/tokens/insikt.tokens'

type FilterMissionsButtonsProps = {
  missionsStatus: MissionStatus[]
  selected: MissionStatus
  setSelected: (status: MissionStatus) => void
}

const FilterMissionsButtons: React.FC<FilterMissionsButtonsProps> = ({
  missionsStatus,
  selected,
  setSelected,
}) => {
  const { t } = useTranslation()

  const handleSelected = (status: MissionStatus) => {
    setSelected(status)
  }

  return (
    <>
      {missionsStatus?.map((status, id) => {
        return (
          <Pill
            key={id}
            aria-pressed='true'
            size='xsmall'
            aria-activedescendant={selected}
            className={selected === status ? 'selected' : ''}
            label={
              status === MissionStatus.Active
                ? t('features.coaching-session.mission-categories.active-missions.title')
                : t('features.coaching-session.overview.completed-missions')
            }
            onClick={() => handleSelected(status)}
            style={{
              padding: `${spacing50} ${spacing100}`,
              border: selected === status ? `2px solid ${BrandColour.Blue}` : 'none',
            }}
          />
        )
      })}
    </>
  )
}

export default FilterMissionsButtons
