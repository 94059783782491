import { IdleEasterEgg } from './IdleEasterEgg'
import { KonamiCodeEasterEgg } from './KonamiCodeEasterEgg'

/**
 * Container component for all global easter eggs.
 */
const EasterEggs: React.FC = () => {
  return (
    <>
      <IdleEasterEgg />
      <KonamiCodeEasterEgg />
    </>
  )
}

export default EasterEggs
