import { Paragraph } from 'components/primitives/Text'
import styled from 'styled-components'

export const BouncyContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`

export const BouncyEmoji = styled(Paragraph)`
  @keyframes hor-movement {
    from {
      top: 0%;
    }
    to {
      top: calc(100% - 4rem);
    }
  }

  @keyframes ver-movement {
    from {
      left: 0%;
    }
    to {
      left: calc(100% - 4rem);
    }
  }

  position: absolute;

  line-height: 1;
  font-size: 4rem;
  z-index: 1000;
  animation-name: hor-movement, ver-movement;
  animation-duration: 10s, 9.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;

  user-select: none;
`
