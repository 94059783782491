import { radiusS } from '@ingka/variables'
import { Box } from '@mantine/core'

import { colourGreyNeutral2, spacing50 } from 'styles/tokens/insikt.tokens'

interface InfoParagraphBoxProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const InfoParagraphBox = ({ children }: InfoParagraphBoxProps) => (
  <Box
    bg={colourGreyNeutral2}
    p={`${spacing50}`}
    mt={spacing50}
    style={{ borderRadius: radiusS }}
  >
    {children}
  </Box>
)

export default InfoParagraphBox
