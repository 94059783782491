import Text from '@ingka/text'
import { space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CSATMetrics } from 'lib/types/coworkerData.types'

import { colourGreyNeutral4 } from 'styles/tokens/insikt.tokens'

import CsatInfoBody from './components/CsatInfoBody.component'
import MainMetric from './components/MainMetric.component'
import AptitudeGraph from 'components/composites/Graphs/aptitudeGraph.component'
import InfoPanel from 'components/composites/Shared/InfoPanel/InfoPanel.component'

import SubPanelLayout from './SubPanel.layout'

interface Props {
  data: CSATMetrics
}

const CsatSubPanel = ({ data }: Props) => {
  return (
    <SubPanelLayout
      leftPanel={<CsatLeftPanel data={data} />}
      rightPanel={<CsatRightPanel data={data} />}
    />
  )
}

const CsatLeftPanel = ({ data }: { data: CSATMetrics }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  return (
    <Flex direction={'column'} gap={space100} w={'100%'} justify={'space-evenly'}>
      <Flex justify={'space-between'}>
        <Flex direction={'column'}>
          <Text headingSize="m">{t('features.1-efficiency.your-now', { metric: 'CSAT' })}</Text>
          <Text bodySize="s">
            {t('features.1-efficiency.your-now-subtitle', { metric: 'CSAT' })}
          </Text>
        </Flex>
        <InfoPanel
          body={<CsatInfoBody />}
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          handleOpen={() => setShowModal(true)}
        />
      </Flex>

      <MainMetric
        value={data.csatR30.coworkerCsat}
        avg={data.csatR30.avgCoworkerCsat}
        top={data.csatR30.maxCoworkerCsat}
        color={'#005980'}
        unit={'%'}
        topPerformerText={'features.1-efficiency.country-highest'}
      />

      <Text bodySize="s" style={{ fontWeight: 'bold' }}>
        {t('features.1-efficiency.csat-consists-of')}
      </Text>
      <Flex justify={'start'} gap={16}>
        <Flex direction={'row'} p={8} gap={16} align={'center'} w={'100%'}>
          <div style={{ backgroundColor: '#0096D2', height: '100%', width: 8 }} />
          <Flex direction={'column'}>
            <Text headingSize="s">{data.csatR30.manner}%</Text>
            <Text bodySize="s">{t('features.1-efficiency.manner')}</Text>
          </Flex>
        </Flex>
        <Flex direction={'row'} p={8} gap={16} align={'center'} w={'100%'}>
          <div style={{ backgroundColor: '#8CDDFF', height: '100%', width: 8 }} />
          <Flex direction={'column'}>
            <Text headingSize="s">{data.csatR30.knowledge}%</Text>
            <Text bodySize="s">{t('features.1-efficiency.knowledge')}</Text>
          </Flex>
        </Flex>
        <Flex direction={'row'} p={8} gap={16} align={'center'} w={'100%'}>
          <div style={{ backgroundColor: '#A3ACB0', height: '100%', width: 8 }} />
          <Flex direction={'column'}>
            <Text headingSize="s">{data.csatR30.solution}%</Text>
            <Text bodySize="s">{t('features.1-efficiency.solution')}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex
          py={12}
          px={16}
          w={'100%'}
          direction={'column'}
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: colourGreyNeutral4,
          }}
        >
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {Math.floor(data.csatR30.surveyResponses / (data.csatR30.responseRate / 100))}
          </Text>
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {t('features.1-efficiency.interactions')}
          </Text>
        </Flex>
        <Flex
          py={12}
          px={16}
          w={'100%'}
          direction={'column'}
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: colourGreyNeutral4,
          }}
        >
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {data.csatR30.surveyResponses}
          </Text>
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {t('features.1-efficiency.responses')}
          </Text>
        </Flex>
        <Flex
          py={12}
          px={16}
          w={'100%'}
          direction={'column'}
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: colourGreyNeutral4,
          }}
        >
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {data.csatR30.responseRate + '%'}
          </Text>
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {t('features.1-efficiency.response-rate')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const CsatRightPanel = ({ data }: { data: CSATMetrics }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      <Flex direction={'column'} style={{ marginBottom: space50 }}>
        <Text headingSize="m">{t('features.1-efficiency.your-trend', { metric: 'CSAT' })}</Text>
        <Text bodySize="s">
          {t('features.1-efficiency.your-trend-subtitle', { metric: 'CSAT' })}
        </Text>
      </Flex>
      <Flex w={'100%'} h={300}>
        <AptitudeGraph data={data.csatR8weeks} />
      </Flex>
    </Flex>
  )
}

export default CsatSubPanel
