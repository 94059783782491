import { ChangableCountrySettings, Country, CountrySettings } from 'lib/types/country.types'

import { apiSlice } from 'state/slices/api/api.slice'

type UpdateCountrySettingInput = {
  settings: ChangableCountrySettings
  countryCode: string
}

type CreateCountryInput = {
  countryName: string
  currencyCode: string
  suggestResolutionFocus: boolean
  suggestSalesFocus: boolean
}

const countrySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCountries: builder.query<Country[], void>({
      query: () => ({
        url: `/countries`,
      }),
      providesTags: ['Country'],
    }),
    updateCountrySettings: builder.mutation<void, UpdateCountrySettingInput>({
      query: ({ countryCode, settings }) => ({
        url: `/countries/${countryCode}/settings`,
        method: 'PUT',
        body: { value: settings },
      }),
      invalidatesTags: ['Country'],
    }),
    createCountry: builder.mutation<void, { countryCode: string; value: CreateCountryInput }>({
      query: ({ countryCode, value }) => ({
        url: `/countries/${countryCode}`,
        method: 'POST',
        body: {
          value,
        },
      }),
      invalidatesTags: ['Country'],
    }),
    fetchCountrySettings: builder.query<CountrySettings, string>({
      query: (countryCode) => ({
        url: `/countries/${countryCode}/settings`,
      }),
      providesTags: (result, error, countryCode) => [{ type: 'Country', id: countryCode }],
    }),
  }),
})

export const {
  useFetchCountriesQuery: useCountries,
  useUpdateCountrySettingsMutation: useUpdateCountrySettings,
  useCreateCountryMutation: useCreateCountry,
  useFetchCountrySettingsQuery,
} = countrySlice
