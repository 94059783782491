import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { RepeatContact } from 'lib/types/coworkerData.types'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

interface RepeatContactGraphProps {
  data: RepeatContact
}

const RepeatContactGraph = ({ data }: RepeatContactGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={500} height={300} data={data.repeatTrendWeek}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={'period'} />
        <YAxis unit={'%'} width={100} />
        <Tooltip
          content={({ active, payload, label }: any) => {
            if (active && payload && payload.length) {
              return (
                <Flex miw={200} bg={backgroundColourDefault} direction={'column'} p={space50}>
                  <Text headingSize="s">{label}</Text>
                  <Text>{`Avg. repeat contact: ${payload[0].value}%`}</Text>
                  <Text>{`Handled volume: ${payload[0].payload.volume}`}</Text>
                </Flex>
              )
            }
          }}
        />

        <Area
          dataKey="repeatShare"
          name="Repeat contact value"
          fill={'#FFA6DA'}
          stroke={'#FFA6DA'}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default RepeatContactGraph
