import { useIdle } from '@mantine/hooks'

import * as Styled from './IdleEasterEgg.styling'

/** 3 hours */
const timeoutDuration = 3 * 60 * 60 * 1000

/**
 * Shows a sleeping emoji in the bottom right corner of the screen
 * if the user has been idle for long enough
 */
const IdleEasterEgg: React.FC = () => {
  const isIdle = useIdle(timeoutDuration, { initialState: false })

  return isIdle ? (
    <Styled.BouncyContainer>
      <Styled.BouncyEmoji>{'😴'}</Styled.BouncyEmoji>
    </Styled.BouncyContainer>
  ) : null
}

export default IdleEasterEgg
