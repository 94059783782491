import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCoworker } from 'state/slices/api'

export const useLayoutData = () => {
  const { coworkerId } = useParams()
  const navigate = useNavigate()

  const coworkerQuery = useCoworker(coworkerId)
  const { data: coworker, isSuccess } = coworkerQuery

  // If the co-worker does not exist, navigate back to the home page
  useEffect(() => {
    if (isSuccess && coworker == null) {
      navigate('/')
    }
  }, [coworker, isSuccess, navigate])

  return coworkerQuery
}
