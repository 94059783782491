import chart from '@ingka/ssr-icon/paths/data-chart'
import globe from '@ingka/ssr-icon/paths/globe'
import person from '@ingka/ssr-icon/paths/person'
import reward from '@ingka/ssr-icon/paths/reward'
import { Grid } from '@mantine/core'
import { Link } from 'react-router-dom'

import { Widget, WidgetHeader } from 'components/composites/Shared/Widget'
import { PageHeader } from 'components/layout/PageHeader'

const AdminPage = () => (
  <>
    <PageHeader title="Admin" />
    <Grid>
      <AdminTile title="Search For Users" headerIcon={person} link="/admin/users" />

      <AdminTile title="Manage Countries" headerIcon={globe} link="/admin/countries" />
      <AdminTile title="Manage User Onboarding" headerIcon={person} link="/admin/user-fixtures" />

      <AdminTile title="Admin stats" headerIcon={chart} link="/admin/admin-stats" />
    </Grid>
  </>
)

interface AdminTileProps {
  title: string
  headerIcon: any
  link: string
}

const AdminTile: React.FC<AdminTileProps> = ({ title, headerIcon, link }) => (
  <Grid.Col span={{ base: 4, md: 4, lg: 4 }}>
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Widget
        header={<WidgetHeader ssrIcon={headerIcon} title={title} centerHeader />}
        style={{ minHeight: '5rem' }}
      />
    </Link>
  </Grid.Col>
)

export default AdminPage
