import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'

import { defaultLocale } from './locales.config'

const options = {
  ns: ['translations'],
  backend: {
    loadPath: `/locales/{{lng}}/{{ns}}.json`,
  },
  lng: defaultLocale.isoCode,
  fallbackLng: 'en-XZ',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  /**
   * Returns an object that includes information about the used language, namespace, key and value
   * @default false
   */
  returnObjects: true, // returns object where applicable
}

export const initTranslations = () =>
  i18n.use(initReactI18next).use(HttpApi).use(intervalPlural).init(options)

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'translation'
    resources: {
      translation: {
        [key: string]: string
      }
    }
  }
}
