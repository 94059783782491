/**
 * rgba(0, 133, 62, 1)
 */
export const colourCampaignSustainability = 'rgba(0, 133, 62, 1)'
/**
 * rgba(224, 7, 81, 1)
 */
export const colourIconFavourite = 'rgba(224, 7, 81, 1)'
/**
 * rgba(17, 17, 17, 1)
 */
export const colourIconDefault = 'rgba(17, 17, 17, 1)'
/**
 * rgba(146, 146, 146, 1)
 */
export const colourIconLight = 'rgba(146, 146, 146, 1)'
/**
 * rgba(255, 255, 255, 1)
 */
export const colourIconInverse = 'rgba(255, 255, 255, 1)'
/**
 * rgba(223, 223, 223, 1)
 */
export const colourDisabledUi = 'rgba(223, 223, 223, 1)'
/**
 * rgba(17, 17, 17, 1)
 */
export const colourBorderDark = 'rgba(17, 17, 17, 1)'
/**
 * rgba(146, 146, 146, 1)
 */
export const colourBorderDefault = 'rgba(146, 146, 146, 1)'
/**
 * rgba(223, 223, 223, 1)
 */
export const colourBorderLight = 'rgba(223, 223, 223, 1)'
/**
 * rgba(255, 255, 255, 1)
 */
export const colourBorderInverse = 'rgba(255, 255, 255, 1)'
/**
 * rgba(17, 17, 17, 1)
 */
export const colourTextDark = 'rgba(17, 17, 17, 1)'
/**
 * rgba(72, 72, 72, 1)
 */
export const colourTextDefault = 'rgba(72, 72, 72, 1)'
/**
 * rgba(118, 118, 118, 1)
 */
export const colourTextLightest = 'rgba(118, 118, 118, 1)'
/**
 * rgba(255, 255, 255, 1)
 */
export const colourTextInverse = 'rgba(255, 255, 255, 1)'
/**
 * rgba(0, 88, 163, 1)
 */
export const colourBgAccent = 'rgba(0, 88, 163, 1)'
/**
 * rgba(17, 17, 17, 1)
 */
export const colourBgInverse = 'rgba(17, 17, 17, 1)'
/**
 * rgba(245, 245, 245, 1)
 */
export const colourBgAlternative = 'rgba(245, 245, 245, 1)'
/**
 * rgba(255, 255, 255, 1)
 */
export const colourBgDefault = 'rgba(255, 255, 255, 1)'
/**
 * rgba(204, 0, 8, 1)
 */
export const colourCmsgBtiRed = 'rgba(204, 0, 8, 1)'
/**
 * rgba(255, 219, 0, 1)
 */
export const colourCmsgBtiYellow = 'rgba(255, 219, 0, 1)'
/**
 * rgba(0, 124, 193, 1)
 */
export const colourCmsgIkeaFamily = 'rgba(0, 124, 193, 1)'
/**
 * rgba(204, 0, 8, 1)
 */
export const colourCmsgNlp = 'rgba(204, 0, 8, 1)'
/**
 * rgba(202, 80, 8, 1)
 */
export const colourCmsgNew = 'rgba(202, 80, 8, 1)'
/**
 * rgba(0, 88, 163, 1)
 */
export const colourSemanticInformative = 'rgba(0, 88, 163, 1)'
/**
 * rgba(10, 138, 0, 1)
 */
export const colourSemanticPositive = 'rgba(10, 138, 0, 1)'
/**
 * rgba(255, 165, 36, 1)
 */
export const colourSemanticCaution = 'rgba(255, 165, 36, 1)'
/**
 * rgba(224, 7, 81, 1)
 */
export const colourSemanticNegative = 'rgba(224, 7, 81, 1)'
/**
 * rgba(0, 62, 114, 1)
 */
export const colourButtonPrimaryEmphasisedPress = 'rgba(0, 62, 114, 1)'
/**
 * rgba(0, 79, 147, 1)
 */
export const colourButtonPrimaryEmphasisedHover = 'rgba(0, 79, 147, 1)'
/**
 * rgba(0, 88, 163, 1)
 */
export const colourButtonPrimaryEmphasisedDefault = 'rgba(0, 88, 163, 1)'
/**
 * rgba(255, 255, 255, 0.15)
 */
export const colourButtonInverseDisabledLight = 'rgba(255, 255, 255, 0.15)'
/**
 * rgba(255, 255, 255, 0.15)
 */
export const colourButtonInverseBorderDefault = 'rgba(255, 255, 255, 0.15)'
/**
 * rgba(255, 255, 255, 0.3)
 */
export const colourButtonInverseBorderHover = 'rgba(255, 255, 255, 0.3)'
/**
 * rgba(255, 255, 255, 1)
 */
export const colourButtonInverseBorderPress = 'rgba(255, 255, 255, 1)'
/**
 * rgba(255, 255, 255, 0.3)
 */
export const colourButtonTertiaryInversePress = 'rgba(255, 255, 255, 0.3)'
/**
 * rgba(128, 128, 128, 1)
 */
export const colourSwitchOffHover = 'rgba(128, 128, 128, 1)'
/**
 * rgba(128, 128, 128, 1)
 */
export const colourSwitchOffPressed = 'rgba(128, 128, 128, 1)'
/**
 * rgba(255, 255, 255, 0)
 */
export const colourTransparent = 'rgba(255, 255, 255, 0)'
/**
 * rgba(0, 0, 0, 0.15)
 */
export const colourBackdropLight = 'rgba(0, 0, 0, 0.15)'
/**
 * rgba(0, 0, 0, 0.7)
 */
export const colourBackdropDark = 'rgba(0, 0, 0, 0.7)'
/**
 * rgba(17, 17, 17, 0.15)
 */
export const colourButtonInverseDisabledDark = 'rgba(17, 17, 17, 0.15)'
/**
 * rgba(0, 0, 0, 1)
 */
export const colourButtonPrimaryPress = 'rgba(0, 0, 0, 1)'
/**
 * rgba(51, 51, 51, 1)
 */
export const colourButtonPrimaryHover = 'rgba(51, 51, 51, 1)'
/**
 * rgba(17, 17, 17, 1)
 */
export const colourButtonPrimaryDefault = 'rgba(17, 17, 17, 1)'
/**
 * rgba(184, 0, 41, 1)
 */
export const colourButtonDangerPress = 'rgba(184, 0, 41, 1)'
/**
 * rgba(204, 0, 61, 1)
 */
export const colourButtonDangerHover = 'rgba(204, 0, 61, 1)'
/**
 * rgba(224, 7, 81, 1)
 */
export const colourButtonDangerDefault = 'rgba(224, 7, 81, 1)'
/**
 * rgba(17, 17, 17, 1)
 */
export const colourNeutralGrey900 = 'rgba(17, 17, 17, 1)'
/**
 * rgba(72, 72, 72, 1)
 */
export const colourNeutralGrey700 = 'rgba(72, 72, 72, 1)'
/**
 * rgba(146, 146, 146, 1)
 */
export const colourNeutralGrey500 = 'rgba(146, 146, 146, 1)'
/**
 * rgba(204, 204, 204, 1)
 */
export const colourNeutralGrey300 = 'rgba(204, 204, 204, 1)'
/**
 * rgba(223, 223, 223, 1)
 */
export const colourNeutralGrey200 = 'rgba(223, 223, 223, 1)'
/**
 * rgba(245, 245, 245, 1)
 */
export const colourNeutralGrey100 = 'rgba(245, 245, 245, 1)'
/**
 * rgba(255, 255, 255, 1)
 */
export const colourNeutralWhite = 'rgba(255, 255, 255, 1)'
/**
 * rgba(0, 62, 114, 1)
 */
export const colourPrimaryAccentDarker = 'rgba(0, 62, 114, 1)'
/**
 * rgba(0, 79, 147, 1)
 */
export const colourPrimaryAccentDark = 'rgba(0, 79, 147, 1)'
/**
 * rgba(0, 88, 163, 1)
 */
export const colourPrimaryAccent = 'rgba(0, 88, 163, 1)'
/**
 * rgba(204, 0, 8, 1)
 */
export const colourBrandRed = 'rgba(204, 0, 8, 1)'
/**
 * rgba(255, 219, 0, 1)
 */
export const colourBrandYellow = 'rgba(255, 219, 0, 1)'
/**
 * rgba(0, 88, 163, 1)
 */
export const colourBrandBlue = 'rgba(0, 88, 163, 1)'
/**
 * 5rem
 */
export const fontSize1500 = '5rem'
/**
 * 4.5rem
 */
export const fontSize1400 = '4.5rem'
/**
 * 4rem
 */
export const fontSize1300 = '4rem'
/**
 * 3.5rem
 */
export const fontSize1200 = '3.5rem'
/**
 * 3.125rem
 */
export const fontSize1100 = '3.125rem'
/**
 * 2.875rem
 */
export const fontSize1000 = '2.875rem'
/**
 * 2.5rem
 */
export const fontSize900 = '2.5rem'
/**
 * 2.25rem
 */
export const fontSize800 = '2.25rem'
/**
 * 2rem
 */
export const fontSize700 = '2rem'
/**
 * 1.75rem
 */
export const fontSize600 = '1.75rem'
/**
 * 1.5rem
 */
export const fontSize500 = '1.5rem'
/**
 * 1.375rem
 */
export const fontSize400 = '1.375rem'
/**
 * 1.25rem
 */
export const fontSize300 = '1.25rem'
/**
 * 1.125rem
 */
export const fontSize200 = '1.125rem'
/**
 * 1rem
 */
export const fontSize100 = '1rem'
/**
 * 0.875rem
 */
export const fontSize75 = '0.875rem'
/**
 * 0.75rem
 */
export const fontSize50 = '0.75rem'
/**
 * 0.6875rem
 */
export const fontSize25 = '0.6875rem'
/**
 * 400
 */
export const fontWeightRegular = 400
/**
 * 700
 */
export const fontWeightBold = 700
/**
 * 2px
 */
export const thicknessThick = '2px'
/**
 * 1px
 */
export const thicknessThin = '1px'
/**
 * 64px
 */
export const radiusCap = '64px'
/**
 * 20px
 */
export const radiusXl = '20px'
/**
 * 12px
 */
export const radiusL = '12px'
/**
 * 8px
 */
export const radiusM = '8px'
/**
 * 4px
 */
export const radiusS = '4px'
/**
 * 0px
 */
export const radiusNone = '0px'
/**
 * 13.5rem
 */
export const space1350 = '13.5rem'
/**
 * 10rem
 */
export const space1000 = '10rem'
/**
 * 7.5rem
 */
export const space750 = '7.5rem'
/**
 * 5.5rem
 */
export const space550 = '5.5rem'
/**
 * 4rem
 */
export const space400 = '4rem'
/**
 * 3rem
 */
export const space300 = '3rem'
/**
 * 2.5rem
 */
export const space250 = '2.5rem'
/**
 * 2rem
 */
export const space200 = '2rem'
/**
 * 1.5rem
 */
export const space150 = '1.5rem'
/**
 * 1.25rem
 */
export const space125 = '1.25rem'
/**
 * 1rem
 */
export const space100 = '1rem'
/**
 * 0.75rem
 */
export const space75 = '0.75rem'
/**
 * 0.5rem
 */
export const space50 = '0.5rem'
/**
 * 0.25rem
 */
export const space25 = '0.25rem'
/**
 * 0px 4px 16px rgba(0, 0, 0, 0.1)
 */
export const shadowSizeDefault = '0px 4px 16px rgba(0, 0, 0, 0.1)'
/**
 * 0
 */
export const durationInstantWeb = 0
/**
 * 0
 */
export const durationInstantMobileNative = 0
/**
 * 0
 */
export const durationInstantXlScreen = 0
/**
 * 100ms
 */
export const durationQuickWeb = '100ms'
/**
 * 150ms
 */
export const durationQuickMobileNative = '150ms'
/**
 * 300ms
 */
export const durationQuickXlScreen = '300ms'
/**
 * 200ms
 */
export const durationStandardWeb = '200ms'
/**
 * 300ms
 */
export const durationStandardMobileNative = '300ms'
/**
 * 600ms
 */
export const durationStandardXlScreen = '600ms'
/**
 * 250ms
 */
export const durationSlowWeb = '250ms'
/**
 * 400ms
 */
export const durationSlowMobileNative = '400ms'
/**
 * 800ms
 */
export const durationSlowXlScreen = '800ms'
/**
 * cubic-bezier(0.4, 0.0, 0.4, 1.0)
 */
export const easeEasy = 'cubic-bezier(0.4, 0.0, 0.4, 1.0)'
/**
 * cubic-bezier(0.8, 0.0, 0.0, 1.0)
 */
export const easeExpressive = 'cubic-bezier(0.8, 0.0, 0.0, 1.0)'
/**
 * cubic-bezier(0.0, 0.0, 0.1, 1.0)
 */
export const easeEnter = 'cubic-bezier(0.0, 0.0, 0.1, 1.0)'
/**
 * cubic-bezier(0.9, 0.0, 1.0, 1.0)
 */
export const easeExit = 'cubic-bezier(0.9, 0.0, 1.0, 1.0)'
/**
 * cubic-bezier(0.45, 1.45, 0.65, 1.0)
 */
export const easeBob = 'cubic-bezier(0.45, 1.45, 0.65, 1.0)'
/**
 * cubic-bezier(0,0,1,1)
 */
export const easeLinear = 'cubic-bezier(0,0,1,1)'
/**
 * 33ms
 */
export const delayTight = '33ms'
/**
 * 100ms
 */
export const delayStandard = '100ms'
/**
 * 150ms
 */
export const delayEmphasised = '150ms'
/**
 * 37.5em
 */
export const breakpointM = '37.5em'
/**
 * 56.25em
 */
export const breakpointL = '56.25em'
/**
 * 75em
 */
export const breakpointXl = '75em'
/**
 * 1.520
 */
export const lineHeight1100Long = '1.520'
/**
 * 1.320
 */
export const lineHeight1100Short = '1.320'
/**
 * 1.500
 */
export const lineHeight1200Long = '1.500'
/**
 * 1.357
 */
export const lineHeight1200Short = '1.357'
/**
 * 1.500
 */
export const lineHeight1300Long = '1.500'
/**
 * 1.300
 */
export const lineHeight1300Short = '1.300'
/**
 * 1.500
 */
export const lineHeight1400Long = '1.500'
/**
 * 1.280
 */
export const lineHeight1400Short = '1.280'
/**
 * 1.500
 */
export const lineHeight1500Long = '1.500'
/**
 * 1.300
 */
export const lineHeight1500Short = '1.300'
/**
 * 1.460
 */
export const lineHeight1000Long = '1.460'
/**
 * 1.300
 */
export const lineHeight1000Short = '1.300'
/**
 * 1.500
 */
export const lineHeight900Long = '1.500'
/**
 * 1.300
 */
export const lineHeight900Short = '1.300'
/**
 * 1.500
 */
export const lineHeight800Long = '1.500'
/**
 * 1.333
 */
export const lineHeight800Short = '1.333'
/**
 * 1.500
 */
export const lineHeight700Long = '1.500'
/**
 * 1.375
 */
export const lineHeight700Short = '1.375'
/**
 * 1.570
 */
export const lineHeight600Long = '1.570'
/**
 * 1.357
 */
export const lineHeight600Short = '1.357'
/**
 * 1.670
 */
export const lineHeight500Long = '1.670'
/**
 * 1.450
 */
export const lineHeight500Short = '1.450'
/**
 * 1.545
 */
export const lineHeight400Long = '1.545'
/**
 * 1.364
 */
export const lineHeight400Short = '1.364'
/**
 * 1.600
 */
export const lineHeight300Long = '1.600'
/**
 * 1.400
 */
export const lineHeight300Short = '1.400'
/**
 * 1.555
 */
export const lineHeight200Long = '1.555'
/**
 * 1.444
 */
export const lineHeight200Short = '1.444'
/**
 * 1.625
 */
export const lineHeight100Long = '1.625'
/**
 * 1.500
 */
export const lineHeight100Short = '1.500'
/**
 * 1.571
 */
export const lineHeight75Long = '1.571'
/**
 * 1.571
 */
export const lineHeight75Short = '1.571'
/**
 * 1.500
 */
export const lineHeight50Long = '1.500'
/**
 * 1.500
 */
export const lineHeight50Short = '1.500'
/**
 * 1.450
 */
export const lineHeight25Long = '1.450'
/**
 * 1.450
 */
export const lineHeight25Short = '1.450'
/**
 * -0.014em
 */
export const letterSpacing1500 = '-0.014em'
/**
 * -0.0124em
 */
export const letterSpacing1400 = '-0.0124em'
/**
 * -0.011em
 */
export const letterSpacing1300 = '-0.011em'
/**
 * -0.0098em
 */
export const letterSpacing1200 = '-0.0098em'
/**
 * -0.0087em
 */
export const letterSpacing1100 = '-0.0087em'
/**
 * -0.0078em
 */
export const letterSpacing1000 = '-0.0078em'
/**
 * -0.007em
 */
export const letterSpacing900 = '-0.007em'
/**
 * -0.0063em
 */
export const letterSpacing800 = '-0.0063em'
/**
 * -0.0056em
 */
export const letterSpacing700 = '-0.0056em'
/**
 * -0.0049em
 */
export const letterSpacing600 = '-0.0049em'
/**
 * -0.0042em
 */
export const letterSpacing500 = '-0.0042em'
/**
 * -0.0039em
 */
export const letterSpacing400 = '-0.0039em'
/**
 * 0em
 */
export const letterSpacing300 = '0em'
/**
 * 0em
 */
export const letterSpacing200 = '0em'
/**
 * 0em
 */
export const letterSpacing100 = '0em'
/**
 * 0em
 */
export const letterSpacing75 = '0em'
/**
 * 0em
 */
export const letterSpacing50 = '0em'
/**
 * 0em
 */
export const letterSpacing25 = '0em'
