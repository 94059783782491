export const qarGroups = {
  QAROP: {
    id: 'QAROP',
    order: 0,
    questions: {
      QAR01: {
        id: 'QAR01',
        order: 0,
      },

      QAR02: {
        id: 'QAR02',
        order: 1,
      },

      QAR03: {
        id: 'QAR03',
        order: 2,
      },
      QAR04: {
        id: 'QAR04',
        order: 2,
      },
    },
  },
  QARSO: {
    id: 'QARSO',
    order: 1,
    questions: {
      QAR05: {
        id: 'QAR05',
        order: 0,
      },

      QAR06: {
        id: 'QAR06',
        order: 1,
      },

      QAR07: {
        id: 'QAR07',
        order: 2,
      },
    },
  },
  QARBI: {
    id: 'QARBI',
    order: 2,
    questions: {
      QAR08: {
        id: 'QAR08',
        order: 0,
      },

      QAR09: {
        id: 'QAR09',
        order: 1,
      },

      QAR10: {
        id: 'QAR10',
        order: 2,
      },
    },
  },
  QAREN: {
    id: 'QAREN',
    order: 3,
    questions: {
      QAR11: {
        id: 'QAR11',
        order: 0,
      },
    },
  },
}

export type QarTotalKey = 'QARTT'
export type QarGroupKey = keyof typeof qarGroups
export type QarQuestionKey =
  | keyof (typeof qarGroups)['QAROP']['questions']
  | keyof (typeof qarGroups)['QARSO']['questions']
  | keyof (typeof qarGroups)['QARBI']['questions']
  | keyof (typeof qarGroups)['QAREN']['questions']
export type QarKey = QarTotalKey | QarGroupKey | QarQuestionKey

export const isQarGroupKey = (id: string): id is QarGroupKey =>
  Object.keys(qarGroups).includes(id)

export const isQarQuestionKey = (id: string): id is QarQuestionKey =>
  Object.keys(qarGroups.QAROP.questions).includes(id) ||
  Object.keys(qarGroups.QARSO.questions).includes(id) ||
  Object.keys(qarGroups.QARBI.questions).includes(id) ||
  Object.keys(qarGroups.QAREN.questions).includes(id)

export const isQarKey = (id: string): id is QarKey =>
  id === 'QARTT' || isQarGroupKey(id) || isQarQuestionKey(id)
