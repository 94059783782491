import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { auth } from 'config/firebase/firebase.config'
import { defaultLocale, locales } from 'config/internationalisation/locales.config'

import {
  useFetchCoworkersByTeamleadIdQuery,
  useFindCoworkerByIdQuery,
} from 'state/slices/api/modules/coworker'
import { useFetchTeamQuery } from 'state/slices/api/modules/team'
import { useFetchPrefsQuery, useFetchUserQuery } from 'state/slices/api/modules/user'

import { useFetchCountrySettingsQuery } from './modules/country/country.slice'
import { useFetchLanguageQuery } from './modules/translations'

export const useTranslatedQuestions = () => {
  const { data: prefs } = useUserPrefsQuery()
  const languageCode = prefs?.locale ? prefs.locale.split('-')[0].toUpperCase() : skipToken
  return useFetchLanguageQuery(languageCode)
}

export const useLocale = () => {
  const { data: prefs } = useUserPrefsQuery()
  const localeIsoCode = prefs?.locale ?? defaultLocale.isoCode

  return locales[localeIsoCode]
}

export const useUserPrefsQuery = () => {
  const { data: user } = useFetchUserQuery(auth.currentUser?.uid ?? skipToken)
  const userId = user?.id ?? skipToken
  return useFetchPrefsQuery(userId)
}

export const useCoworker = (coworkerId: string | undefined | SkipToken) => {
  return useFindCoworkerByIdQuery(coworkerId ?? skipToken)
}

export const useCoworkers = (teamleadId: string | undefined) => {
  return useFetchCoworkersByTeamleadIdQuery(teamleadId ?? skipToken)
}

export const useTeamQuery = (teamId?: string | SkipToken) => {
  return useFetchTeamQuery(teamId ?? skipToken)
}

export const useCountrySettings = (coworkerId: string) => {
  const { data: coworker } = useCoworker(coworkerId)
  return useFetchCountrySettingsQuery(coworker?.country ?? skipToken)
}
