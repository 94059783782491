import SSRIcon from '@ingka/ssr-icon'
import calendar from '@ingka/ssr-icon/paths/calendar'
import { Flex, Group } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useFormatDate } from 'hooks/formatting'

import { radius25, spacing25 } from 'styles/tokens/insikt.tokens'
import { colourNeutralWhite, colourSemanticCaution } from 'styles/tokens/oldSkapa.tokens'

import { Heading, Paragraph, SubHeading } from 'components/primitives/Text'

interface UpcomingScheduledSessionProps {
  upcomingCoachingSessionData: {
    session: {
      plannedDate: string
    }
    isOverdue: boolean
    diffInDays: number
  }
}

const UpcomingScheduledSession: React.FC<UpcomingScheduledSessionProps> = ({
  upcomingCoachingSessionData,
}) => {
  const formatDate = useFormatDate()
  const plannedDate = upcomingCoachingSessionData.session.plannedDate
  const { t } = useTranslation()
  const { isOverdue, diffInDays } = upcomingCoachingSessionData

  const transKey = `features.coaching-session.coworker-coaching-sessions.upcoming-session.session-status`
  const plannedDateLeadingZero =
    Math.abs(diffInDays) < 10 ? `0${Math.abs(diffInDays)}` : Math.abs(diffInDays)

  return (
    <>
      <Flex direction="column" justify="center" align="flex-start" gap={spacing25}>
        <Paragraph>
          {t(
            `features.coaching-session.coworker-coaching-sessions.upcoming-session.${isOverdue ? 'session-status.session-is-overdue' : 'subtitle'
            }`
          )}
        </Paragraph>
        <Flex
          bg={colourNeutralWhite}
          px={`${spacing25}`}
          style={{
            borderRadius: radius25,
            alignSelf: 'stretch',
            border: `1px solid ${isOverdue ? colourSemanticCaution : colourNeutralWhite}`,
          }}
          justify={'space-between'}
        >
          <Group gap={4}>
            {plannedDate ? (
              <>
                <Heading headingSize="m">
                  {t(`${transKey}.session-date`, {
                    daysUntilSession: plannedDateLeadingZero,
                  })}
                </Heading>
                <SubHeading bodySize="s">{t(`${transKey}.days`)}</SubHeading>
              </>
            ) : (
              t('common.error.n/a')
            )}
          </Group>

          <SSRIcon paths={calendar} color="black" style={{ width: '2rem', height: '2rem' }} />
        </Flex>
        {plannedDate && <Paragraph>{formatDate(new Date(plannedDate))}</Paragraph>}
      </Flex>
    </>
  )
}

export default UpcomingScheduledSession
