export const qsaGroups = {
  SAC: {
    id: 'SAC',
    order: 0,
    questions: {
      QSA01: {
        id: 'QSA01',
        order: 0,
      },
      QSA02: {
        id: 'QSA02',
        order: 1,
      },
      QSA03: {
        id: 'QSA03',
        order: 2,
      },
      QSA04: {
        id: 'QSA04',
        order: 3,
      },
      QSA05: {
        id: 'QSA05',
        order: 4,
      },
      QSA06: {
        id: 'QSA06',
        order: 5,
      },
      QSA07: {
        id: 'QSA07',
        order: 6,
      },
      QSA08: {
        id: 'QSA08',
        order: 7,
      },
      QSA09: {
        id: 'QSA09',
        order: 8,
      },
      QSA10: {
        id: 'QSA10',
        order: 9,
      },
      QSA11: {
        id: 'QSA11',
        order: 10,
      },
      QSA12: {
        id: 'QSA12',
        order: 11,
      },
      QSA13: {
        id: 'QSA13',
        order: 12,
      },
      QSA14: {
        id: 'QSA14',
        order: 13,
      },
    },
  },
}

export type QsaTotalKey = 'QSATT'
export type QsaGroupKey = keyof typeof qsaGroups
export type QsaQuestionKey = keyof (typeof qsaGroups)['SAC']['questions']
export type QsaKey = QsaTotalKey | QsaGroupKey | QsaQuestionKey

export const isQsaGroupKey = (id: string): id is QsaGroupKey =>
  Object.keys(qsaGroups).includes(id)

export const isQsaQuestionKey = (id: string): id is QsaQuestionKey =>
  Object.keys(qsaGroups.SAC.questions).includes(id)

export const isQsaKey = (id: string): id is QsaKey =>
  id === 'QSATT' || isQsaGroupKey(id) || isQsaQuestionKey(id)
