import Text from '@ingka/text'
import { Flex } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import { useTranslation } from 'react-i18next'

import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useFormatDate } from 'hooks/formatting'
import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions } from 'state/slices/api'

type NextScheduledCoachingProps = {
  coworkerId: string
}

const NextScheduledCoaching: React.FC<NextScheduledCoachingProps> = ({ coworkerId }) => {
  const { t } = useTranslation()
  const pageType = useCurrentPageType()
  const formatDate = useFormatDate()

  const { data: coachingSessions = [] } = useCoachingSessions(coworkerId ?? skipToken)

  const upcomingSessionData = CoachingSessionHelper.getUpcomingCoachingSessionData(coachingSessions)

  if (!upcomingSessionData) {
    return null
  }

  const getDateText = () => {
    if (
      upcomingSessionData.isPlanned &&
      !upcomingSessionData.isOverdue &&
      upcomingSessionData.diffInDays === 0
    ) {
      // Today
      return t('features.coaching-session.session-is-due-today')
    } else if (upcomingSessionData.isPlanned && !upcomingSessionData.isOverdue) {
      // Planned
      if (pageType === 'coworker') {
        return t('features.coaching-session.session-in-x-days-coworker', {
          daysUntil: Math.abs(upcomingSessionData.diffInDays),
          formattedDate: formatDate(new Date(upcomingSessionData.formattedDate)),
        })
      }
      return t('features.coaching-session.session-in-x-days', {
        daysUntil: Math.abs(upcomingSessionData.diffInDays),
        formattedDate: formatDate(new Date(upcomingSessionData.formattedDate)),
      })
    } else if (upcomingSessionData.isPlanned && upcomingSessionData.isOverdue) {
      // Overdue
      return (
        <Text style={{ fontWeight: 'bolder' }}>
          {t('features.coaching-session.session-is-overdue', {
            daysUntil: Math.abs(upcomingSessionData.diffInDays),
            formattedDate: formatDate(new Date(upcomingSessionData.formattedDate)),
          })}
        </Text>
      )
    }
  }

  return (
    <>
      <Flex justify="left" align="center">
        <Text>{getDateText()}</Text>
      </Flex>
    </>
  )
}

export default NextScheduledCoaching
