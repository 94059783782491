import FormField from '@ingka/form-field'
import RadioButton from '@ingka/radio-button'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from 'hooks/users'

import { useCoworker, useSetCoworkerRoleMutation } from 'state/slices/api'

import { Subtitle } from 'components/primitives/Text'

import { Section } from '../SideMenu.styling'

const CoworkerRoleMenuPage = () => {
  const { t } = useTranslation()
  const [setCoworkerRole] = useSetCoworkerRoleMutation()

  const [selectedRole, setSelectedRole] = useState<string>()

  const { data: user } = useUser()
  const { data: coworker } = useCoworker(user?.legacyId ?? skipToken)

  const currentRole = coworker?.role

  useEffect(() => {
    setSelectedRole(currentRole)
  }, [currentRole])

  const handleRoleSelected = (role: string) => {
    setSelectedRole(role)
    if (user) {
      setCoworkerRole({ coworkerId: user.legacyId, role })
    }
  }

  return (
    <Section>
      <Subtitle>{t('navigation.sidebar-right.select-language.title')}</Subtitle>

      <FormField>
        <RadioButton
          id="sales"
          value="sales"
          label="Sales"
          checked={selectedRole === 'sales'}
          onChange={() => handleRoleSelected('sales')}
        />
      </FormField>
      <FormField>
        <RadioButton
          id="resolution"
          value="resolution"
          label="Resolution"
          checked={selectedRole === 'resolution'}
          onChange={() => handleRoleSelected('resolution')}
        />
      </FormField>
      <FormField>
        <RadioButton
          id="both"
          value="both"
          label={t('common.roles.both')}
          checked={selectedRole === 'both'}
          onChange={() => handleRoleSelected('both')}
        />
      </FormField>
    </Section>
  )
}

export default CoworkerRoleMenuPage
