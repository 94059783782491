import Text from '@ingka/text'
import { Box, Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { spacing100 } from 'styles/tokens/insikt.tokens'

import { SubTitleBlue } from 'components/primitives/Text/Text.component'

import * as Styled from '../WelcomeModal.styling'

export const QsWelcomeModalContent = () => {
  const { t } = useTranslation()
  return (
    <>
      <Flex align={'center'} direction="column">
        <SubTitleBlue>{t('introduction.welcome-modal.title')}</SubTitleBlue>
      </Flex>
      <Styled.ModalBodyImage src="/images/party-popper.gif" />

      <Box style={{ backgroundColor: '#F1F4FE', width: '100%', padding: spacing100 }}>
        <Text bodySize="m">{t('introduction.welcome-modal.qs-content')}</Text>
      </Box>
    </>
  )
}
