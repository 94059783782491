import { Divider, Grid } from '@mantine/core'
import { FC, ReactNode } from 'react'

import {
  colourGreyNeutral2,
  colourGreyNeutral3,
  radius25,
  spacing50,
  spacing75,
  spacing100,
} from 'styles/tokens/insikt.tokens'

import { GridVariant } from './GridLayout.types'
import { getSpanFromVariant } from './GridLayout.utils'

const defaultStyle = {
  backgroundColor: colourGreyNeutral2,
  alignText: 'center',
  borderRadius: radius25,
  width: '100%',
  padding: spacing100,
  marginTop: spacing100,
}

export interface GridElement {
  variant?: GridVariant
  element: ReactNode
  height?: string
  styles?: React.CSSProperties
}

interface GridLayoutProps {
  children: GridElement[]
  gridStyles?: React.CSSProperties
  noDivider?: boolean
  padding?: string | number
}

export const GridLayout: FC<GridLayoutProps> = ({
  children,
  gridStyles = {},
  noDivider = false,
  padding,
}) => {
  return (
    <Grid
      justify="center"
      gutter={spacing75}
      style={{
        ...defaultStyle,
        ...gridStyles,
        padding: padding ?? defaultStyle.padding,
      }}
    >
      {children.map((child, index) => (
        <Grid.Col
          key={index}
          style={{
            height: child.height ?? 'auto',
            ...(child.styles ?? {}),
            width: '100%',
          }}
          span={getSpanFromVariant(child.variant ?? GridVariant.FullLine)}
        >
          {child.element}
        </Grid.Col>
      ))}
      {!noDivider && <DividerColumn />}
    </Grid>
  )
}

export const DividerColumn = () => (
  <Grid.Col
    span={getSpanFromVariant(GridVariant.FullLine)}
    style={{
      marginBottom: spacing50,
    }}
  >
    <Divider size={'xs'} color={colourGreyNeutral3} />
  </Grid.Col>
)
