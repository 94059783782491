import Text from '@ingka/text'

import { colourSemanticNegative } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

export const DifferenceText = styled(Text)<{
  difference: number
}>`
  position: relative;
  color: ${({ difference }) => {
    if (difference > 0) {
      return 'green'
    }
    if (difference < 0) {
      return 'red'
    }
    return 'inherit'
  }};
`

export const ErrorMessage = styled(Text)`
  color: ${colourSemanticNegative};
`
