import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const CsatInfoBody = () => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'} gap={space50}>
      <Text headingSize="m">
        {t('features.1-efficiency.csat-info.understanding-your-csat-dashboard')}
      </Text>
      <Text headingSize="s">{t('features.1-efficiency.csat-info.your-csat-now-left-panel')}</Text>
      <Text bodySize="s">
        {t('features.1-efficiency.csat-info.represents-your-current-performance')}
      </Text>
      <Text headingSize="xs">{t('features.1-efficiency.csat-info.key-highlights')}</Text>
      <Text bodySize="s">{t('features.1-efficiency.csat-info.overall-csat-score')}</Text>

      <Text headingSize="xs">{t('features.1-efficiency.csat-info.breakdown-by-categories')}</Text>

      <ul>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.csat-info.manner')}</Text>
        </li>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.csat-info.knowledge')}</Text>
        </li>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.csat-info.solution')}</Text>
        </li>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.csat-info.response-volume')}</Text>
        </li>
      </ul>

      <Text headingSize="s">
        {t('features.1-efficiency.csat-info.your-historical-csat-trend-right-panel')}
      </Text>
      <Text bodySize="s">
        {t('features.1-efficiency.csat-info.tracks-how-your-performance-evolves')}
      </Text>
      <Text headingSize="xs">{t('features.1-efficiency.csat-info.key-features')}</Text>

      <ul>
        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.csat-info.weekly-aggregation-of-performance')}
          </Text>
        </li>
        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.csat-info.easily-spot-trends-or-anomalies')}
          </Text>
        </li>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.csat-info.tooltips')}</Text>
        </li>
      </ul>

      <Text headingSize="xs">
        {t('features.1-efficiency.csat-info.the-transition-from-now-to-trend')}
      </Text>
      <ul>
        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.csat-info.now-panel-snapshot-of-current-performance')}
          </Text>
        </li>
        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.csat-info.trend-panel-identify-patterns-over-time')}
          </Text>
        </li>
      </ul>
    </Flex>
  )
}

export default CsatInfoBody
