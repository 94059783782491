import Pill from '@ingka/pill'
import verySatisfied from '@ingka/ssr-icon/paths/feedback-happy'
import veryDisatisfied from '@ingka/ssr-icon/paths/feedback-sad'
import { Divider } from '@mantine/core'
import { render } from '@testing-library/react'
import { FC } from 'react'

import { SemanticColour, SemanticColourRGBA } from 'styles/tokens/insikt.tokens'

import { Paragraph } from 'components/primitives/Text'

type CustomerCommentMetricProps = {
  manner: string
  knowledge: string
  solution: string
  csat: string
}

const CustomerCommentMetrics: FC<CustomerCommentMetricProps> = ({
  manner,
  knowledge,
  solution,
  csat,
}) => {
  const surveyMetrics = [
    {
      title: 'Manner',
      score: manner,
    },
    {
      title: 'Knowledge',
      score: knowledge,
    },
    {
      title: 'Solution',
      score: solution,
    },
  ]

  const getPillProperties = (score: string) => {
    if (score >= '4') {
      return {
        background: SemanticColourRGBA.Positive,
        fill: SemanticColour.Positive,
        icon: verySatisfied,
      }
    } else {
      return {
        background: SemanticColourRGBA.Negative,
        fill: SemanticColour.Negative,
        icon: veryDisatisfied,
      }
    }
  }

  const renderPill = (title: string, score: string) => {
    const { background, fill, icon } = getPillProperties(score)
    return (
      <Pill
        key={title}
        label={
          <Paragraph style={{ color: 'black', fontWeight: 'normal' }} bodySize="s">
            {title}
          </Paragraph>
        }
        size="xsmall"
        iconPosition={'leading'}
        ssrIcon={icon}
        style={{
          background,
          color: fill,
          pointerEvents: 'none',
        }}
      />
    )
  }

  return (
    <>
      {renderPill('CSAT', csat)}
      <Divider orientation="vertical" />
      {surveyMetrics.map(({ title, score }) => renderPill(title, score))}
    </>
  )
}

export default CustomerCommentMetrics
