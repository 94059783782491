import Button from '@ingka/button'
import Select, { Option } from '@ingka/select'
import Tooltip from '@ingka/tooltip'
import { Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { spacing100 } from 'styles/tokens/insikt.tokens'

import InfoPanel from 'components/composites/Shared/InfoPanel/InfoPanel.component'
import { Widget, WidgetFooter, WidgetHeader } from 'components/composites/Shared/Widget'
import FeatureInfoFAQ from 'components/features/FeatureInfoFAQ/FeatureInfoFAQ.component'
import { DatePicker } from 'components/primitives/DatePicker'

import { useHandleCreateCoachingSessionClicked } from './NoPlannedCoachingSessionPrompt.hooks'
import { NoPlannedCoachingSessionPromptProps } from './NoPlannedCoachingSessionPrompt.types'

const NoPlannedCoachingSessionPrompt: React.FC<NoPlannedCoachingSessionPromptProps> = ({
  coworkerId,
  coachingSessionCompare,
}) => {
  const { t } = useTranslation()

  const { teamleadId } = useParams()
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [createCoachingSession, { isLoading: isLoadingCreateCoachingSession }] =
    useHandleCreateCoachingSessionClicked()

  // Calculate the difference in days between todays date and the planned date of the latest completed/signed coaching sesion
  const today = dayjs() // Get todays date
  // Calculate the absolute value of the difference between today and the latest planned coaching session
  const diff = Math.abs(dayjs(coachingSessionCompare?.plannedDate).diff(today, 'days'))

  // Set the state for planned date to default to today + the diff calculated above
  const [plannedDate, setPlannedDate] = useState<Date>(dayjs().add(diff, 'days').toDate())
  // Set the state for assessments needed to the number of assessments for the last session
  const [assessmentsNeeded, setAssessmentsNeeded] = useState<number>(
    coachingSessionCompare ? coachingSessionCompare.assessmentsNeeded : 3
  )

  // Values for choosing number of assessments
  const assessmentsOptions = ['1', '2', '3', '4', '5', '6']

  return (
    <Widget
      style={{ marginTop: spacing100 }}
      header={
        <Flex justify={'space-between'} align={'start'} style={{ height: '100%' }}>
          <WidgetHeader title={t('features.coaching-session.no-coaching-session-planned')} />
          <Tooltip tooltipText={'Coaching (FAQ)'} position='top'>
            <InfoPanel
              showModal={showInfoModal}
              handleOpen={() => setShowInfoModal(true)}
              handleClose={() => setShowInfoModal(false)}
              body={<FeatureInfoFAQ namespace="features.coaching-session.info-panel" />}
            />
          </Tooltip>
        </Flex>
      }
      footer={
        <WidgetFooter>
          <Select
            id="selectAssessments"
            label={t('features.coaching-session.actions.choose-assessments')}
            onChange={(e) => {
              setAssessmentsNeeded(Number(e.target.value))
            }}
            value={String(assessmentsNeeded)}
          >
            {assessmentsOptions.map((value) => {
              return <Option name={value} value={value} key={value} />
            })}
          </Select>
          <DatePicker
            id="1"
            label={t('features.coaching-session.actions.choose-date')}
            value={plannedDate}
            onChange={(v) => {
              setPlannedDate(v)
            }}
            minDate={new Date()}
          ></DatePicker>
          <Button
            small
            type="primary"
            text={t('features.coaching-session.actions.plan-coaching-session')}
            onClick={() => {
              analyticsHelper.createCustomElementClickEvent(
                {
                  key: CustomElementClickEventKeys.PlanCoachingSession,
                  description:
                    'Custom event when teamlead clicks on plan for the first coaching session.',
                },
                {
                  coworkerId: coworkerId,
                  teamleadId: teamleadId,
                  neededAssessments: assessmentsNeeded,
                  chosenDate: plannedDate,
                }
              )
              createCoachingSession(coworkerId, plannedDate, assessmentsNeeded)
            }}
            loading={isLoadingCreateCoachingSession}
            style={{ alignSelf: 'end' }}
          />
        </WidgetFooter>
      }
    ></Widget>
  )
}

export default NoPlannedCoachingSessionPrompt
