import Text from '@ingka/text'
import { space25, space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import {
  backgroundColourWhite,
  colourGreyNeutral3,
  colourGreyNeutral4,
} from 'styles/tokens/insikt.tokens'

interface Props {
  value: number | string | null
  unit: string
  avg: number | string
  top: number | string | null
  hideBest?: boolean
  color?: string
  topPerformerText?:
    | 'features.1-efficiency.country-best'
    | 'features.1-efficiency.country-highest'
    | 'features.1-efficiency.country-lowest'
}

const MainMetric = ({
  value,
  unit,
  avg,
  top,
  color,
  topPerformerText = 'features.1-efficiency.country-best',
  hideBest,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Flex
      direction={'row'}
      align={'center'}
      py={space100}
      px={space50}
      w={'100%'}
      gap={16}
      style={{
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: colourGreyNeutral4,
      }}
    >
      <Flex gap={16} align={'center'}>
        <div
          style={{ backgroundColor: color ? color : colourGreyNeutral3, height: 60, width: 16 }}
        />
        <Text style={{ whiteSpace: 'nowrap' }} headingSize="s">
          {value ? value + unit : 'N/A'}
        </Text>
      </Flex>

      <Flex direction={'row'} justify={'space-evenly'} align={'center'} w={'100%'}>
        <Flex direction={'column'} bg={backgroundColourWhite} p={space25} align={'center'}>
          <Text headingSize="xs">{avg ? avg + unit : 'N/A'}</Text>
          <Text bodySize="s">{t('features.1-efficiency.country-average')}</Text>
        </Flex>

        {!hideBest && (
          <Flex direction={'column'} bg={backgroundColourWhite} p={space25} align={'center'}>
            <Text headingSize="xs">{top ? top + unit : 'N/A'}</Text>
            <Text bodySize="s">{t(topPerformerText)}</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default MainMetric
