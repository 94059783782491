import { CoworkerRole } from 'config/domain/coworkerRoles.config'

import { CoachingSession } from './coachingSession.types'
import { CustomerComment } from './customerComment.types'
import { Interaction } from './interaction.types'
import { Mission } from './mission.types'
import { RecentQAs } from './qualityAssessment.types'

export enum CoworkerCoachingStatus {
  Plan = 'plan',
  Assess = 'assess',
  Prepare = 'prepare',
  Prepared = 'prepared',
  Coach = 'coach',
  NoAction = 'no-action',
}

export type Coworker = {
  legacyId: string
  fullName: string
  firstName: string
  lastName: string
  email: string
  country: string
  teamId: string
  role: CoworkerRole
  photoData?: UserPhoto
  recentQAs: RecentQAs
  interactions: Interaction[]
  coachingSessions: CoachingSession[]
  missions: Mission[]
  roleTitle?: string
  customerComments: CustomerComment[]
  teamleadId?: string
}

export type CoworkerStatusObject = {
  status: CoworkerCoachingStatus
  assessmentsNeeded: number
  assessmentsCompleted: number
}

export type PhotoMeta = {
  lastUpdatedAt?: string
  etag: string
  contentType: string
}

export type UserPhoto = {
  photo: string
  metadata?: PhotoMeta
}
