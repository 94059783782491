import humanizeDuration, { HumanizerOptions } from 'humanize-duration'

import { useLocale } from 'state/slices/api/api.hooks'

export const useHumanizeDuration = () => {
  const { dateFnsCode } = useLocale()
  return (number: number, options?: HumanizerOptions) =>
    humanizeDuration(number, {
      language: dateFnsCode,
      fallbacks: ['en'],
      ...options,
    })
}
