import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import Text from '@ingka/text'
import { space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface Props {
  showModal: boolean
  handleClose: () => void
}

const EfficiencyInfoModal = ({ showModal, handleClose }: Props) => {
  return (
    <Modal visible={showModal} handleCloseBtn={handleClose}>
      <Sheets header={<ModalHeader></ModalHeader>} footer={<ModalFooter>{''}</ModalFooter>}>
        <ModalBody>
          <>
            <GeneralInfoBody />
            <SalesSuggestedFocusBody />
          </>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

const GeneralInfoBody = () => {
  const { t } = useTranslation()
  return (
    <>
      <Flex direction={'column'} gap={space50}>
        <Text headingSize="m">
          {t('features.1-efficiency.general-info.balancing-speed-and-quality-in-rcmp-interactions')}
        </Text>

        <Text bodySize="s">
          {t('features.1-efficiency.general-info.when-handling-customer-interactions')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.general-info.speed')}</Text>
          </li>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.general-info.quality')}</Text>
          </li>
          <ul>
            <li>
              <Text bodySize="s">
                {t('features.1-efficiency.general-info.customer-satisfaction')}
              </Text>
            </li>
            <li>
              <Text bodySize="s">
                {t('features.1-efficiency.general-info.repeat-contact-share')}
              </Text>
            </li>
            <li>
              <Text bodySize="s">{t('features.1-efficiency.general-info.sales-performance')}</Text>
            </li>
          </ul>
        </ul>

        <Text bodySize="s">
          {t('features.1-efficiency.general-info.each-metric-uses-different-units')}
        </Text>

        <Text headingSize="s">
          {t('features.1-efficiency.general-info.suggested-focus-a-guiding-tool')}
        </Text>

        <Text bodySize="s">
          {t('features.1-efficiency.general-info.to-simplify-decision-making')}
        </Text>

        <Text headingSize="xs">{t('features.1-efficiency.general-info.purpose')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.general-info.highlights-the-metric-where-you-can')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t(
                'features.1-efficiency.general-info.helps-ensure-a-balance-between-speed-and-quality'
              )}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.general-info.how-to-use-it')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.general-info.its-a-suggestion-not-a-directive')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.general-info.discuss-with-your-team-leader')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.general-info.maintaining-balance')}</Text>

        <Text bodySize="s">{t('features.1-efficiency.general-info.how-it-works')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.general-info.if-your-performance-changes')}
            </Text>
            <ul>
              <li>
                <Text bodySize="s">
                  {t('features.1-efficiency.general-info.example-if-speed-improves')}
                </Text>
              </li>
            </ul>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.general-info.no-suggested-focus')}</Text>

        <Text bodySize="s">
          {t('features.1-efficiency.general-info.this-indicates-your-performance-is-well-balanced')}
        </Text>

        <Text headingSize="s">
          {t('features.1-efficiency.general-info.suggested-focus-logic-for-resolution-roles')}
        </Text>

        <Text bodySize="s">
          {t(
            'features.1-efficiency.general-info.the-system-prioritizes-metrics-based-on-their-impact'
          )}
        </Text>

        <Text headingSize="xs">
          {t('features.1-efficiency.general-info.interaction-volume-requirements')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.general-info.repeat-contact-share')}</Text>
          </li>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.general-info.csat')}</Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.general-info.flagging-criteria')}</Text>

        <Text headingSize="xs">{t('features.1-efficiency.general-info.csat-flagged-if')}</Text>

        <ul>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.general-info.below-70-percent-1')}</Text>
          </li>

          <li>
            <Text bodySize="s">
              {t(
                'features.1-efficiency.general-info.below-85-percent-if-aht-or-repeat-contact-share-are-not-flagged'
              )}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.general-info.aht-flagged-if')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.general-info.flagged-if-reducing-your-aht')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t(
                'features.1-efficiency.general-info.however-aht-wont-be-flagged-if-your-repeat-contact-share'
              )}
            </Text>
          </li>
        </ul>

        <Text bodySize="s">{t('features.1-efficiency.general-info.key-takeaway')}</Text>
      </Flex>
    </>
  )
}

const SalesSuggestedFocusBody = () => {
  const { t } = useTranslation()
  return (
    <>
      <Flex direction={'column'} gap={space50} mt={space100}>
        <Text headingSize="s">
          {t('features.1-efficiency.sales-suggested-focus.suggested-focus-logic-for-sales-roles')}
        </Text>

        <Text bodySize="s">
          {t(
            'features.1-efficiency.sales-suggested-focus.the-system-identifies-your-suggested-focus-area'
          )}
        </Text>

        <Text headingSize="xs">
          {t('features.1-efficiency.sales-suggested-focus.interaction-volume-requirements')}
        </Text>

        <Text bodySize="s">
          {t('features.1-efficiency.sales-suggested-focus.to-calculate-focus-areas')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.sales-suggested-focus.conversion')}</Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.sales-suggested-focus.acv-average-customer-value')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.sales-suggested-focus.aht-average-handle-time')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.sales-suggested-focus.csat-customer-satisfaction')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">
          {t('features.1-efficiency.sales-suggested-focus.flagging-criteria')}
        </Text>

        <Text headingSize="xs">
          {t('features.1-efficiency.sales-suggested-focus.conversion-opportunity')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t(
                'features.1-efficiency.sales-suggested-focus.if-your-conversion-performance-shows-room'
              )}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">
          {t('features.1-efficiency.sales-suggested-focus.acv-opportunity')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.sales-suggested-focus.if-conversion-is-not-flagged')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">
          {t('features.1-efficiency.sales-suggested-focus.aht-opportunity')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t(
                'features.1-efficiency.sales-suggested-focus.if-neither-conversion-nor-acv-is-flagged'
              )}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">
          {t('features.1-efficiency.sales-suggested-focus.csat-opportunity')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.sales-suggested-focus.if-no-opportunities-are-flagged')}
            </Text>
          </li>
        </ul>

        <Text bodySize="s">
          {t('features.1-efficiency.sales-suggested-focus.this-logic-ensures-your-coaching-focus')}
        </Text>
      </Flex>
    </>
  )
}

export default EfficiencyInfoModal
