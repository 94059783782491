import { useNavigate } from 'react-router-dom'

import { Notification as NotificationModel } from 'lib/types/user.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useUser } from 'hooks/users'

import { useDismissNotificationMutation } from 'state/slices/api'

import { Paragraph } from 'components/primitives/Text'

import * as Styled from './Notification.styling'

type NotificationProps = {
  notification: NotificationModel
}

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const navigate = useNavigate()
  const { rootPath } = useSectionPaths()
  const { data: user } = useUser()
  const [markNotificationAsRead] = useDismissNotificationMutation()

  return (
    <Styled.Background>
      <Styled.Button
        type="plain"
        read={notification.read}
        onClick={() => {
          if (user) {
            markNotificationAsRead({
              userId: user.id,
              notificationId: notification.id,
            })
          }
          navigate(`${rootPath}${notification.link}`)
        }}
      >
        {notification.title}
      </Styled.Button>
      <Paragraph>{notification.message}</Paragraph>
    </Styled.Background>
  )
}

export default Notification
