import Button from '@ingka/button'
import Modal, { ModalFooter, Prompt } from '@ingka/modal'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UserRole } from 'lib/types/user.types'

import { useUser } from 'hooks/users'

import { useUserPrefsQuery } from 'state/slices/api'
import { useUpdatePrefsMutation } from 'state/slices/api/modules/user/userPrefs.slice'

import { QsWelcomeModalContent } from './Contents/QsContent.component'
import TeamleadWelcomeModalContent from './TeamleadContent.component'
import { CoworkerWelcomeModal } from './WelcomeModalSkeleton.component'

export const COWORKER_CONTENT_COUNT = 7

const WelcomeModal = () => {
  const { t } = useTranslation()

  const [updatePrefs] = useUpdatePrefsMutation()
  const [visible, setVisible] = useState(false)

  const [contentIndex, setContentIndex] = useState<number>(0)

  const { data: user } = useUser()
  const { data: prefs, isSuccess } = useUserPrefsQuery()

  useEffect(() => {
    if (prefs && prefs.introduction.showWelcomeModal) {
      setVisible(true)
    }
  }, [prefs])

  if (!isSuccess || !user) {
    return null
  }

  const buttonText =
    user.role === UserRole.Coworker
      ? `${contentIndex + 1}/${COWORKER_CONTENT_COUNT}`
      : t('common.actions.close')

  const handleNextClicked = () => {
    if (user.role === UserRole.Coworker) {
      if (contentIndex === COWORKER_CONTENT_COUNT - 1) {
        handleClose()
      } else {
        setContentIndex(contentIndex + 1)
      }
    } else {
      handleClose()
    }
  }

  const handleClose = () => {
    const updatedPrefs = {
      ...prefs,
      introduction: {
        ...prefs.introduction,
        showWelcomeModal: false,
      },
    }

    setVisible(false)
    updatePrefs({ userId: user.id, prefs: updatedPrefs })
  }

  return (
    // Cannot allow to escape the modal for the co-worker since they need to set their role
    // This does not seem to be working for esc key, only for clicking outside of the modal
    <Modal
      escapable={user.role !== UserRole.Coworker}
      visible={visible}
      handleCloseBtn={handleClose}
    >
      <Prompt
        title={null}
        titleId="welcome-modal"
        header={null}
        footer={
          <ModalFooter>
            <Button
              type="primary"
              ssrIcon={arrowRight}
              onClick={handleNextClicked}
              /* disabled on the last page for coworker, as they need to select a role */
              disabled={contentIndex === COWORKER_CONTENT_COUNT - 1}
            >
              {buttonText}
            </Button>
          </ModalFooter>
        }
      >
        {user.role === UserRole.TeamLead && <TeamleadWelcomeModalContent />}
        {user.role === UserRole.Coworker && (
          <CoworkerWelcomeModal index={contentIndex} handleClose={handleClose} />
        )}
        {user.role === UserRole.Qs && <QsWelcomeModalContent />}
      </Prompt>
    </Modal>
  )
}

export default WelcomeModal
