import SSRIcon from '@ingka/ssr-icon'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Text from '@ingka/text'
import { space25, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AverageHandleTime, Department, Focus, SkillData } from 'lib/types/coworkerData.types'
import { displayTime } from 'lib/utils/displayTime.utils'

import { colourBrandYellow, colourSemanticCaution } from 'styles/tokens/oldSkapa.tokens'

import AHTInfoBody from './components/AHTInfoBody.component'
import EmptyPanel from './components/EmptyPanel.component'
import MainMetric from './components/MainMetric.component'
import SubMetric from './components/SubMetric.component'
import { AhtLineGraph } from 'components/composites/Graphs/ahtGraph.component'
import InfoPanel from 'components/composites/Shared/InfoPanel/InfoPanel.component'

import { useSuggestedFocus } from '../useSuggestedFocus.hook'
import SubPanelLayout from './SubPanel.layout'

interface Props {
  data: SkillData
}

const AhtSubPanel = ({ data }: Props) => {
  if (!data.aht) {
    return (
      <SubPanelLayout
        leftPanel={<EmptyPanel title={'AHT'} />}
        rightPanel={<EmptyPanel title={'AHT'} />}
      />
    )
  }

  return (
    <SubPanelLayout
      leftPanel={<AhtLeftPanel data={data} />}
      rightPanel={<AhtRightPanel data={data.aht} />}
    />
  )
}

const AhtLeftPanel = ({ data }: { data: SkillData }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const suggestedFocusOpacity = useSuggestedFocus(Focus.AHT, data)

  const totalAht =
    data.aht.ahtR30.avgAcwTime + data.aht.ahtR30.avgHoldTime + data.aht.ahtR30.avgTalkTime
  const showTopPart = !data.aht.ahtR30.enoughVolume || suggestedFocusOpacity === 1
  return (
    <Flex direction={'column'} gap={space100} w={'100%'} justify={'space-evenly'}>
      {showTopPart && (
        <Flex justify={'space-between'}>
          <Text
            headingSize="s"
            style={{
              backgroundColor: colourBrandYellow,
              padding: space25,
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              opacity: suggestedFocusOpacity,
            }}
          >
            {t('features.1-efficiency.highest-potential')}
          </Text>
          {!data.aht.ahtR30.enoughVolume && (
            <Text bodySize="s" style={{ textAlign: 'center' }}>
              <SSRIcon
                paths={warningTriangle}
                style={{ marginRight: space25 }}
                color={colourSemanticCaution}
              />
              {t('features.1-efficiency.low-data-volume')}
            </Text>
          )}
        </Flex>
      )}

      <Flex justify={'space-between'}>
        <Flex direction={'column'}>
          <Text headingSize="m">{t('features.1-efficiency.your-now', { metric: 'AHT' })}</Text>
          <Text bodySize="s">
            {t('features.1-efficiency.your-now-subtitle', { metric: 'AHT' })}
          </Text>
        </Flex>
        <InfoPanel
          body={<AHTInfoBody />}
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          handleOpen={() => setShowModal(true)}
        />
      </Flex>

      <Flex style={{ opacity: data?.aht?.ahtR30.enoughVolume ? 1 : 0.5 }}>
        <MainMetric
          value={data.aht.ahtR30.aht}
          avg={data.aht.ahtR30.avgAht}
          top={data.aht.ahtR30.minAht}
          hideBest={data.department === Department.Sales}
          unit=" min."
          color="#008ca4e2"
          topPerformerText={'features.1-efficiency.country-lowest'}
        />
      </Flex>

      <Text bodySize="s" style={{ fontWeight: 'bold' }}>
        {t('features.1-efficiency.aht-consists-of')}
      </Text>

      <Flex
        direction={'row'}
        justify={'space-between'}
        align={'center'}
        style={{ opacity: data?.aht?.ahtR30.enoughVolume ? 1 : 0.5 }}
      >
        <SubMetric
          value={displayTime(data.aht.ahtR30.avgTalkTime)}
          topText={`${Math.round((data.aht.ahtR30.avgTalkTime / totalAht) * 100)}%`}
          description={'Avg. ' + t('features.1-efficiency.talk-time')}
          color="#009985"
        />
        <SubMetric
          value={displayTime(data.aht.ahtR30.avgHoldTime)}
          topText={`${Math.round((data.aht.ahtR30.avgHoldTime / totalAht) * 100)}%`}
          description={'Avg. ' + t('features.1-efficiency.hold-time')}
          color="#A3ACB0"
        />
        <SubMetric
          value={displayTime(data.aht.ahtR30.avgAcwTime)}
          topText={`${Math.round((data.aht.ahtR30.avgAcwTime / totalAht) * 100)}%`}
          description={'Avg. ' + t('features.1-efficiency.acw-time')}
          color="#008ca4e2"
        />
      </Flex>
    </Flex>
  )
}

const AhtRightPanel = ({ data }: { data: AverageHandleTime }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      <Text headingSize="m">{t('features.1-efficiency.your-trend', { metric: 'AHT' })}</Text>
      <Text bodySize="s">{t('features.1-efficiency.your-trend-subtitle', { metric: 'AHT' })}</Text>
      {data.ahtTrendWeek.length > 0 ? (
        <Flex w={'100%'} h={300} style={{ marginTop: space100 }}>
          <AhtLineGraph data={data} />
        </Flex>
      ) : (
        <Text>{t('No AHT trend data available')}</Text>
      )}
    </Flex>
  )
}

export default AhtSubPanel
