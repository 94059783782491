import { ISODateString } from 'lib/types/common.types'

/** The type of owner an indicator belongs to; a person or a team. */
export enum MetricOwnerType {
  Coworker = 'coworker',
  Team = 'team',
}

/** An entity that an indicator relates to; a person or a team. */
export type MetricOwner = {
  id: string
  type: MetricOwnerType
}

/** Represents a range of dates spanning from `start` to `end`. */
export type TimeRange = {
  /** A string representing when the TimeRange starts. */
  start: ISODateString
  /** A string representing when the TimeRange ends. */
  end: ISODateString
}

/** Represents the method used to compute the metric; with or without smoothing applied. */
export enum MetricValueType {
  Raw = 'normal',
  Trend = 'trend',
}

/**
 * The type of period represented by an indicator; a day or month.
 * Weeks are currently not supported, but may be in the future.
 */
export enum PeriodType {
  Day = 'day',
  Month = 'month',
}

/** Represents an indicator computed for a single period. */
export type PeriodicMetric = {
  period: string
  value: number
}
