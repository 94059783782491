import { collection, getDocs } from '@firebase/firestore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { firestore } from 'config/firebase/firebase.config'

interface StatusDoc {
  date: string
  status: number
  translationKey: string
}

export const useWarningMessage = () => {
  const [statuses, setStatuses] = useState<string[]>([])
  const { t } = useTranslation()
  useEffect(() => {
    const fetchAndUpdateStatuses = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'dataTransferStatus'))
      const data: string[] = querySnapshot.docs
        .map((doc) => doc.data() as StatusDoc)
        .filter((data) => data.status !== 200)
        .map((data) => data.translationKey)
      setStatuses(data)
    }

    fetchAndUpdateStatuses()
  }, [])

  const statusText = statuses
    .map((status) => t(`features.data-transfer-status.sync-names.${status}`))
    .join(', ')
  const tooltipText = `${t(`features.data-transfer-status.tooltip-message`)}: ${statusText}`

  return { showWarning: statuses.length > 0, tooltipText }
}
