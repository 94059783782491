import { radiusL, space100, thicknessThin } from '@ingka/variables'

import {
  colourBgAlternative,
  colourBgDefault,
  colourBorderLight,
} from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

export const ButtonContainer = styled.div`
  position: relative;
`

export const MenuContent = styled.div`
  width: 300px;
  background-color: ${colourBgDefault};
  border-radius: ${radiusL};
`

export const MenuPositioner = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-264px, 56px);
  border: ${thicknessThin} solid ${colourBorderLight};
  border-radius: ${radiusL};
`

type NotificationProps = {
  divider?: boolean
}

export const Notification = styled.div<NotificationProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${space100} ${space100};
  border-bottom: ${(props) =>
    props.divider ? `${thicknessThin} solid ${colourBorderLight}` : 'none'};
  &:hover {
    background-color: ${colourBgAlternative};
  }
`
