import Button from '@ingka/button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Mission } from 'lib/types/mission.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { useSuggestCompleteMissionClicked } from 'hooks/missions/useMissionActions.hooks'
import { useUser } from 'hooks/users'

import { PromptModal } from 'components/composites/Shared/PromptModal'

const CompleteMissionButton = ({
  mission,
  coworkerId,
}: {
  mission: Mission
  coworkerId: string
}) => {
  const { t } = useTranslation()
  const user = useUser()
  const [showSuggestCompleteModal, setShowSuggestCompleteModal] = useState(false)
  const [suggestMission, suggestMissionArgs, { isLoading: isLoadingSuggestComplete }] =
    useSuggestCompleteMissionClicked(coworkerId, mission.id, mission.suggestedToComplete)

  // Confirm suggest to complete or undo suggested to complete
  const handleSuggestedCompleteMissionClicked = async () => {
    await suggestMission(suggestMissionArgs)
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.SuggestCompleteMission,
        description:
          'Custom event for tracking when a teamlead clicks on the suggest to complete mission button.',
      },

      {
        ...suggestMissionArgs,
        createdBy: user.data?.legacyId,
      }
    )
    setShowSuggestCompleteModal(false)
  }

  return (
    <>
      <Button
        type={!mission.suggestedToComplete ? 'primary' : 'secondary'}
        small
        text={
          !mission.suggestedToComplete
            ? t('features.mission.actions.suggest-to-complete')
            : t('features.mission.actions.undo-suggest-to-complete')
        }
        loading={isLoadingSuggestComplete}
        onClick={() => setShowSuggestCompleteModal(true)}
      />

      {/* Suggest to complete */}
      {showSuggestCompleteModal && (
        <PromptModal
          title={
            !mission.suggestedToComplete
              ? t('features.mission.confirm-mission-actions-modal.suggest-to-complete.title')
              : t('features.mission.confirm-mission-actions-modal.undo-suggest-to-complete.title')
          }
          titleId={
            !mission.suggestedToComplete
              ? t('suggest-to-complete-mission-modal')
              : t('undo-suggest-to-complete-mission-modal')
          }
          content={
            !mission.suggestedToComplete
              ? t('features.mission.confirm-mission-actions-modal.suggest-to-complete.warnings')
              : t(
                  'features.mission.confirm-mission-actions-modal.undo-suggest-to-complete.warnings'
                )
          }
          onCancel={() => setShowSuggestCompleteModal(false)}
          onConfirm={() => handleSuggestedCompleteMissionClicked()}
          loading={isLoadingSuggestComplete}
        />
      )}
    </>
  )
}

export default CompleteMissionButton
