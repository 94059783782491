import { breakpointL, breakpointM, breakpointXl } from '@ingka/variables'

export const mantineTheme = {
  breakpoints: {
    xs: '0em',
    sm: breakpointM,
    md: breakpointL,
    lg: breakpointXl,
    xl: '93.75em',
  },
}
