import { apiSlice } from 'state/slices/api/api.slice'

type SendFeedbackInput = {
  message: string
  type: string
  legacyId: string
  name: string
  email: string
}

const metaSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation<void, SendFeedbackInput>({
      query: (input) => ({
        url: `/meta/feedback`,
        method: 'POST',
        body: input,
      }),
    }),
  }),
})

export const { useSendFeedbackMutation: useSendFeedback } = metaSlice
