import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const RepeatInfoBody = () => {
  const { t } = useTranslation()
  return (
    <Flex direction={'column'} gap={space50}>
      <Text headingSize="m">
        {t('features.1-efficiency.repeat-info.understanding-repeat-contacts')}
      </Text>
      <Text headingSize="s">{t('features.1-efficiency.repeat-info.what-are-repeat-contacts')}</Text>
      <Text bodySize="s">
        {t('features.1-efficiency.repeat-info.repeat-contact-share-measures')}
      </Text>
      <Text headingSize="s">{t('features.1-efficiency.repeat-info.why-repeats-happen')}</Text>
      <Text bodySize="s">{t('features.1-efficiency.repeat-info.achieving-zero-repeat-unrealistic')}</Text>

      <Text headingSize="s">{t('features.1-efficiency.repeat-info.why-it-matters')}</Text>
      <Text headingSize="xs">{t('features.1-efficiency.repeat-info.reducing-repeat-benefits')}</Text>
      <ul>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.repeat-info.lowers-workload')}</Text>
        </li>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.repeat-info.improves-customer-satisfaction')}</Text>
        </li>
      </ul>

      <Text headingSize="xs">{t('features.1-efficiency.repeat-info.contextualizing-repeat-contact-share')}</Text>
      <Text headingSize="xs">{t('features.1-efficiency.repeat-info.compare-performance-to')}</Text>

      <ul>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.repeat-info.country-average')}</Text>
        </li>
        <li>
          <Text bodySize="s">{t('features.1-efficiency.repeat-info.country-best')}</Text>
        </li>
      </ul>

      <Text headingSize="s">{t('features.1-efficiency.repeat-info.why-its-important')}</Text>
      <Text bodySize="s">{t('features.1-efficiency.repeat-info.understand-achievable-benchmarks')}</Text>

      <Text headingSize="xs">{t('features.1-efficiency.repeat-info.the-importance-of-volume')}</Text>
      <ul>

        <li>
          <Text bodySize="s">{t('features.1-efficiency.repeat-info.minimum-volume-requirement')}</Text>
        </li>

        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.repeat-info.benchmark-threshold')}
          </Text>
        </li>
      </ul>

      <Text headingSize="xs">{t('features.1-efficiency.repeat-info.tracking-repeat-contact-trends')}</Text>
      <ul>
        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.repeat-info.long-term-view')}
          </Text>
        </li>
        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.repeat-info.hover-for-insights')}
          </Text>
        </li>
        <li>
          <Text bodySize="s">
            {t('features.1-efficiency.repeat-info.fluctuations-in-trends')}
          </Text>
        </li>
      </ul>
    </Flex>
  )
}

export default RepeatInfoBody
