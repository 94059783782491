import styled from 'styled-components'

export const LanguageChoicesContainer = styled.div`
  position: relative;
`

export const LoadingBallContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
