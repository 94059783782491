import { space100 } from '@ingka/variables'

import styled from 'styled-components'

type StackProps = {
  minHeight?: string
  alignItems?: 'center' | 'flex-start' | 'flex-end'
}

const Stack = styled.div<StackProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  gap: ${space100};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'unset')};
`

export default Stack
