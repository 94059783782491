import InlineMessage from '@ingka/inline-message'
import { Drawer, Flex } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { MissionStatus } from 'lib/types/mission.types'

import { useCoworker, useMission, useTeamQuery } from 'state/slices/api'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { MissionOverviewProps } from './MissionOverview.types'
import ActiveOverview from './overviewTypes/ActiveOverview.component'
import CompletedOverview from './overviewTypes/CompletedOverview.component'
import DraftOverview from './overviewTypes/DraftOverview.component'

const MissionOverview: React.FC<MissionOverviewProps> = ({ missionId, opened, close }) => {
  const { t } = useTranslation()
  const { coworkerId } = useParams()
  const [internalOpen, setInternalOpen] = useState(false)

  const {
    data: coworker,
    isLoading: isLoadingCoworker,
    isSuccess: isSuccessCoworker,
    isError: isErrorCoworker,
  } = useCoworker(coworkerId ?? skipToken)

  const {
    data: mission,
    isLoading: isLoadingMissions,
    isSuccess: isMissionSucess,
    isError: isErrorMissions,
  } = useMission(coworkerId ? { coworkerId, missionId } : skipToken)

  const {
    data: team,
    isLoading: isLoadingTeam,
    isSuccess: isTeamSuccess,
    isError: isErrorTeam,
  } = useTeamQuery(coworker?.teamId)

  const isLoading = isLoadingCoworker || isLoadingMissions || isLoadingTeam
  const isError = isErrorCoworker || isErrorMissions || isErrorTeam
  const isSuccess = isSuccessCoworker && isMissionSucess && isTeamSuccess

  useEffect(() => {
    setInternalOpen(opened)
  }, [opened])

  return (
    <>
      {/** Success State */}
      <Drawer
        position="bottom"
        size="100%"
        h="100%"
        onClose={() => {
          setInternalOpen(false)
          close()
        }}
        opened={internalOpen}
        transitionProps={{
          duration: 500,
          timingFunction: 'ease',
          transition: 'slide-up',
        }}
        withCloseButton
        withinPortal={true}
        zIndex={1000}
        bg={backgroundColourDefault}
      >
        {/** Loading State */}
        {isLoading && <LoadingIndicator height="500px" />}
        {/** Error State */}
        {isError && <UnexpectedErrorMessage />}
        {isSuccess && (
          <>
            {!coworker && <InlineMessage body={t('features.coworker.errors.not-found')} />}
            {!mission && <InlineMessage body={t('features.mission.errors.not-found')} />}
            {!team && <InlineMessage body={t('features.team.errors.not-found')} />}
            {coworker && team && mission && (
              <Flex style={{ height: '100%', flexGrow: 1 }}>
                {/* Draft mission details */}

                {mission.status === MissionStatus.Draft && (
                  <DraftOverview mission={mission} coworker={coworker} />
                )}

                {/* Active default values and/or with changes made */}
                {mission.status === MissionStatus.Active && (
                  <ActiveOverview mission={mission} coworker={coworker} />
                )}

                {/* Completed missions */}
                {mission.status === MissionStatus.Completed && (
                  <CompletedOverview mission={mission} coworker={coworker} />
                )}
              </Flex>
            )}
          </>
        )}
      </Drawer>
    </>
  )
}

export default MissionOverview
