import Button from '@ingka/button'
import SSRIcon from '@ingka/ssr-icon'
import calendar from '@ingka/ssr-icon/paths/calendar'
import linkOut from '@ingka/ssr-icon/paths/link-out'
import { Box, Divider, Flex, Group } from '@mantine/core'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CustomerComment } from 'lib/types/customerComment.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'
import { useTeamleadId } from 'hooks/id/useTeamleadId.hooks'

import {
  colourGreyNeutral2,
  radius25,
  spacing25,
  spacing50,
  spacing75,
  spacing100,
} from 'styles/tokens/insikt.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import { Paragraph } from 'components/primitives/Text'

import { CustomerCommentInfoGroup, CustomerCommentMetrics } from '../CustomerCommentMetrics'
import { useCustomerCommentInfoGroup } from '../CustomerComments.helper'

interface CustomerCommentContentProps {
  comment: CustomerComment
  actions: ('forward' | 'dismiss')[]
  onConfirm: (action: 'forward' | 'dismiss') => void
}

const CustomerCommentContent: FC<CustomerCommentContentProps> = ({
  comment,
  onConfirm,
  actions,
}) => {
  const { t } = useTranslation()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [selectedAction, setSelectedAction] = useState<'forward' | 'dismiss' | null>(null)

  const handleActionClick = (actionType: 'forward' | 'dismiss') => {
    setSelectedAction(actionType)
    setShowConfirmation(true)
  }

  const handleConfirm = () => {
    if (selectedAction) {
      onConfirm(selectedAction)
    }
    setShowConfirmation(false)
  }

  const handleCancel = () => {
    setShowConfirmation(false)
    setSelectedAction(null)
  }

  return (
    <>
      <Widget style={{ height: '100%' }} border>
        {/* Customer comment content and details  */}
        <CustomerCommentDetails comment={comment} />
        {/* CTA buttons to show confirmation message and buttons */}
        {!showConfirmation && (
          <Flex mt={spacing75} gap={spacing50} justify={'flex-start'} wrap={'wrap'}>
            <>
              {actions.includes('forward') && (
                <Button
                  type="emphasised"
                  small
                  text={t('features.customer-comments.actions.send-to-coworker')}
                  onClick={() => handleActionClick('forward')}
                />
              )}
              {actions.includes('dismiss') && (
                <Button
                  type="tertiary"
                  small
                  text={t('features.customer-comments.actions.dismiss')}
                  onClick={() => handleActionClick('dismiss')}
                />
              )}
            </>
          </Flex>
        )}
        {/* Confirm send or dismiss comment */}
        {showConfirmation && (
          <>
            <Paragraph $bold style={{ marginTop: spacing50 }}>
              {selectedAction === 'forward'
                ? t('features.customer-comments.actions.send-to-coworker-confirmation')
                : t('features.customer-comments.actions.dismiss-comment-confirmation')}
            </Paragraph>
            <Flex mt={spacing75} gap={spacing50} justify={'flex-start'} wrap={'wrap'}>
              <Button
                type="primary"
                small
                text={t('features.customer-comments.actions.confirm')}
                onClick={handleConfirm}
              />
              <Button
                type="tertiary"
                small
                text={t('features.customer-comments.actions.cancel')}
                onClick={handleCancel}
              />
            </Flex>
          </>
        )}
      </Widget>
    </>
  )
}

export default CustomerCommentContent

interface CustomerCommentDetailsProps {
  comment: CustomerComment
}
const CustomerCommentDetails: FC<CustomerCommentDetailsProps> = ({ comment }) => {
  const { t } = useTranslation()
  const teamleadId = useTeamleadId()
  const coworkerId = useCoworkerId()

  const { commentMediaType, commentInteractionDate } = useCustomerCommentInfoGroup(comment)

  const handleLinkClick = () => {
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.LinkCustomerComment,
        description:
          'Custom event for tracking when a teamlead clicks the link on a customer comment.',
      },
      {
        teamleadId: teamleadId,
        coworkerId: coworkerId,
        interactionId: comment.interactionId,
      }
    )
  }

  return (
    <>
      <Flex my={spacing100} justify={'space-between'} wrap={'wrap'}>
        <Group justify={'flex-start'} gap={spacing25}>
          {commentMediaType && (
            <CustomerCommentInfoGroup icon={commentMediaType.icon}>
              {commentMediaType.text}
            </CustomerCommentInfoGroup>
          )}

          <CustomerCommentInfoGroup>
            <SSRIcon paths={calendar} style={{ marginRight: spacing25 }} />
            {commentInteractionDate}
          </CustomerCommentInfoGroup>
        </Group>

        <Link onClick={handleLinkClick} to={`${comment.interactionLink}`} target="_blank">
          <Group gap={spacing25} wrap={'wrap'} align={'center'} justify={'flex-end'} my={spacing25}>
            {t('features.customer-comments.link-to-interaction')}
            <SSRIcon paths={linkOut} />
          </Group>
        </Link>
      </Flex>
      <Divider />

      <Flex my={spacing50} justify={'flex-start'} gap={spacing25} wrap={'wrap'}>
        <CustomerCommentMetrics
          csat={String(comment.custSat)}
          manner={comment.manner}
          knowledge={comment.knowledge}
          solution={comment.solution}
        />
      </Flex>
      <Box mih={'25vh'} bg={colourGreyNeutral2} p={spacing100} style={{ borderRadius: radius25 }}>
        <Paragraph>{comment.freeText}</Paragraph>
      </Box>
      <Divider style={{ marginTop: spacing100 }} />
    </>
  )
}
