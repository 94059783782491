import { breakpointM } from '@ingka/variables'

import styled from 'styled-components'

export const ShowOnlyOnMobile = styled.div`
  @media only screen and (min-width: ${breakpointM}) {
    display: none;
  }
`

export const ShowOnlyOnLargerDevice = styled.div`
  @media (max-width: ${breakpointM}) {
    display: none;
  }
`
