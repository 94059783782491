import Button from '@ingka/button'
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import SSRIcon from '@ingka/ssr-icon'
import informationCircle from '@ingka/ssr-icon/paths/information-circle'

interface Props {
  showModal: boolean
  handleClose: () => void
  handleOpen: () => void
  body: React.ReactNode
  displayType?: 'icon' | 'button' // add a optional prop for displaying as button if needed
}

const InfoPanel = ({ showModal, handleClose, body, handleOpen, displayType }: Props) => {
  return (
    <>
      {displayType === 'button' ? (
        <Button
          small
          text={'Info'}
          onClick={handleOpen}
          ssrIcon={informationCircle}
        />
      ) : (
        <SSRIcon
          paths={informationCircle}
          onClick={handleOpen}
          style={{
            cursor: 'pointer',
          }}
        />
      )}
      <Modal visible={showModal} handleCloseBtn={handleClose}>
        <Sheets header={<ModalHeader />} footer={null}>
          <ModalBody>{body}</ModalBody>
        </Sheets>
      </Modal>
    </>
  )
}

export default InfoPanel
