import Button from '@ingka/button'
import InlineMessage from '@ingka/inline-message'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import SSRIcon from '@ingka/ssr-icon'
import arrowOnPath from '@ingka/ssr-icon/paths/arrow-on-path'
import checkmark from '@ingka/ssr-icon/paths/checkmark'
import cleanSparkles from '@ingka/ssr-icon/paths/clean-sparkles'
import { Accordion, Box, Flex, Stack } from '@mantine/core'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Mission, MissionStatus } from 'lib/types/mission.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useMissionsForCoachingSession } from 'hooks/coachingSessions/useMissionsForCoachingSession.hooks'
import { useFormatDate } from 'hooks/formatting/useFormatDate.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSession, useMissions, useSignCoachingSession } from 'state/slices/api'

import { missionIconSquare } from 'styles/missions'
import { spacing100 } from 'styles/tokens/insikt.tokens'

import { Widget, WidgetHeader } from 'components/composites/Shared/Widget'
import { CoachingSessionOverviewSkeleton } from 'components/features/CoachingSessions/CoachingSessionOverview/CoachingSessionOverviewSkeleton'
import { GreyBoldedSubheading, Paragraph, SubHeading } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { MissionRecap } from '../CoachingSessionEditor/Summary/MissionRecap'
import { CoachingSessionOverviewProps } from './CoachingSessionOverview.types'
import { SessionComment } from './SessionComment'

const CoachingSessionOverview: React.FC<CoachingSessionOverviewProps> = ({
  coworkerId,
  coachingSessionId,
}) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const { coworkerPath } = useSectionPaths()

  const [signCoachingSession, { isLoading: isLoadingSignCoachingSession }] =
    useSignCoachingSession()

  const handleSignCoachingSessionClicked = useCallback(
    () =>
      signCoachingSession({
        coworkerId,
        coachingSessionId,
        signedDate: DateHelper.dateToString(new Date()),
      }).then(() => {
        navigate(`${coworkerPath}/coaching-sessions`)
      }),
    [signCoachingSession, coworkerId, coachingSessionId, navigate, coworkerPath]
  )

  const [showSignModal, setShowSignModal] = useState(false)

  const {
    data: coachingSession,
    isLoading: isLoadingCoachingSession,
    isSuccess: isSuccessCoachingSession,
    isError: isErrorCoachingSession,
  } = useCoachingSession({
    coworkerId,
    coachingSessionId,
  })

  const {
    data: missions,
    completedMissionIds,
    newMissionIds,
    isLoading: isLoadingMissions,
    isSuccess: isSuccessMissions,
    isError: isErrorMissions,
  } = useMissionsForCoachingSession({ coworkerId, coachingSessionId })

  const formattedCompletedDate =
    coachingSession?.completedDate && formatDate(new Date(coachingSession.completedDate))

  // All active missions regardless of the coaching session cycle
  const allMissions: Mission[] = useMissions(coworkerId).data ?? []
  const allActiveMissions = allMissions.filter((mission) => mission.status === MissionStatus.Active)

  const isLoading = isLoadingCoachingSession || isLoadingMissions
  const isSuccess = isSuccessCoachingSession && isSuccessMissions
  const isError = isErrorCoachingSession || isErrorMissions

  return (
    <>
      {isLoading && <CoachingSessionOverviewSkeleton />}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {!coachingSession && <InlineMessage title={t('errors.not-found')} />}
          {coachingSession && (
            <>
              <Stack mt={spacing100} gap={0}>
                <Widget
                  border
                  header={
                    <WidgetHeader
                      title={
                        <SubHeading>
                          {t('features.coaching-session.session-completed-on', {
                            formattedDate: formattedCompletedDate,
                          })}
                        </SubHeading>
                      }
                    />
                  }
                ></Widget>
                <Stack>
                  <SessionComment comment={coachingSession.sessionComment ?? ''} />

                  {!missions ||
                    missions.length === 0 ||
                    ((allActiveMissions.length === 0 || !allActiveMissions) && (
                      <InlineMessage
                        variant="cautionary"
                        title={t('features.mission.warnings.no-data')}
                      />
                    ))}

                  {(missions.length > 0 || allActiveMissions.length > 0) && (
                    <Widget border>
                      <SubHeading>
                        {t('features.coaching-session.overview.related-missions')}
                      </SubHeading>
                      <Accordion
                        key={'related missions'}
                        multiple
                        defaultValue={['Completed missions', 'New missions', 'All active missions']}
                      >
                        <Accordion.Item id="test" key={'completed'} value={'Completed missions'}>
                          <Accordion.Control disabled={completedMissionIds.length === 0}>
                            <Flex direction={'row'} align={'center'} gap={'sm'}>
                              <SSRIcon style={missionIconSquare} paths={checkmark} />
                              <GreyBoldedSubheading>
                                {t('features.coaching-session.overview.completed-missions')}
                              </GreyBoldedSubheading>
                            </Flex>
                          </Accordion.Control>
                          <Accordion.Panel>
                            {missions.map((mission) => {
                              if (!completedMissionIds.includes(mission.id)) return null
                              return (
                                <Box key={mission.id}>
                                  <MissionRecap
                                    mission={mission}
                                    coworkerId={coworkerId}
                                    visualiseChanges={false}
                                    showStatus={false}
                                  />
                                </Box>
                              )
                            })}
                          </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value={'New missions'}>
                          <Accordion.Control disabled={newMissionIds.length === 0}>
                            <Flex direction={'row'} align={'center'} gap={'sm'}>
                              <SSRIcon style={missionIconSquare} paths={cleanSparkles} />
                              <GreyBoldedSubheading>
                                {t('features.coaching-session.overview.new-missions')}
                              </GreyBoldedSubheading>
                            </Flex>
                          </Accordion.Control>
                          <Accordion.Panel>
                            {missions.map((mission) => {
                              if (!newMissionIds.includes(mission.id)) return null
                              return (
                                <Box my={spacing100} key={mission.id}>
                                  <MissionRecap
                                    mission={mission}
                                    coworkerId={coworkerId}
                                    visualiseChanges={false}
                                    showStatus={false}
                                  />
                                </Box>
                              )
                            })}
                          </Accordion.Panel>
                        </Accordion.Item>

                        {allActiveMissions.length > 0 && (
                          <Accordion.Item
                            id="all-active-missions"
                            key={'All active'}
                            value={'All active missions'}
                          >
                            <Accordion.Control>
                              <Flex direction={'row'} align={'center'} gap={'sm'}>
                                <SSRIcon style={missionIconSquare} paths={arrowOnPath} />
                                <GreyBoldedSubheading>
                                  {t('features.coaching-session.overview.all-active-missions')}
                                </GreyBoldedSubheading>
                              </Flex>
                            </Accordion.Control>
                            <Accordion.Panel>
                              {allActiveMissions.map((mission) => {
                                return (
                                  <Box my={spacing100} key={mission.id}>
                                    <MissionRecap
                                      mission={mission}
                                      coworkerId={coworkerId}
                                      visualiseChanges={false}
                                      showStatus={false}
                                    />
                                  </Box>
                                )
                              })}
                            </Accordion.Panel>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </Widget>
                  )}
                </Stack>
              </Stack>

              <Modal visible={showSignModal} handleCloseBtn={() => setShowSignModal(false)}>
                <Prompt
                  title={t('features.coaching-session.actions.sign-coaching-session')}
                  titleId="sign-session-modal"
                  header={<ModalHeader />}
                  footer={
                    <ModalFooter>
                      <Button
                        small
                        type="secondary"
                        text={t('common.actions.cancel')}
                        onClick={() => setShowSignModal(false)}
                      />
                      <Button
                        small
                        type="primary"
                        text={t('features.coaching-session.actions.sign-coaching-session')}
                        onClick={handleSignCoachingSessionClicked}
                        loading={isLoadingSignCoachingSession}
                      />
                    </ModalFooter>
                  }
                >
                  <Paragraph>{t('features.coaching-session.sign-coaching-session.body')}</Paragraph>
                </Prompt>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  )
}

export default CoachingSessionOverview
