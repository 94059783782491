import SSRIcon from '@ingka/ssr-icon'
import {
  breakpointL,
  durationStandardWeb,
  easeEnter,
  radiusL,
  space25,
  space100,
  space125,
  space200,
} from '@ingka/variables'

import {
  backgroundColourWhite,
  shadowDefaultSize,
  shadowRaisedSize,
} from 'styles/tokens/insikt.tokens'
import { colourBorderLight } from 'styles/tokens/oldSkapa.tokens'

import { Caption } from 'components/primitives/Text'
import styled from 'styled-components'

export const InteractionCard = styled.div`
  background-color: ${backgroundColourWhite};
  box-shadow: ${shadowDefaultSize};

  padding: ${space200};
  border-radius: ${radiusL};
  transition: all ${easeEnter} ${durationStandardWeb};

  :hover {
    box-shadow: ${shadowRaisedSize};
  }
`

export const InteractionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${space25};
  column-gap: ${space100};
`

export const CardFlex = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpointL}) {
    grid-template-columns: 1fr auto 1fr;
  }
`

export const Divider = styled.hr`
  border-color: ${colourBorderLight};
  width: 100%;

  @media (min-width: ${breakpointL}) {
    margin: 0 ${space125};
    width: unset;
  }
`

export const InlineIcon = styled(SSRIcon)`
  margin-right: ${space25};
`

export const FieldList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${space25};
`

export const CommentList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${space25};
`

export const Group = styled.div`
  padding: ${space25} 0;
`

export const ScoreRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  column-gap: ${space100};
  margin: ${space25} 0 0 0;
`

export const NotesRow = styled(Caption)`
  margin-left: ${space100};
`

export const EvaluationDateText = styled(Caption)`
  margin-bottom: ${space100};
`
