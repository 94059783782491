export type CoworkerDataDocument = {
  legacyId: string
  coworkerData: CoworkerData
}

export type CoworkerData = {
  contactExperience: number
  countryCode: string
  endDateR30: string
  startDateR30: string
  teamLeaderLegacyId: string
  team: string
  csat: CSATMetrics
  skillData: SkillData[]
}

export type CSATMetrics = {
  csatR8weeks: Rolling8WeeksCSAT[]
  csatR30: Rolling30CSAT
}

export type Rolling8WeeksCSAT = {
  aptitude: number
  manner: number
  coworkerCsat: number
  knowledge: number
  solution: number
  salesWeek: string
  surveyResponses: number
}

export type Rolling30CSAT = {
  aptitude: number
  manner: number
  coworkerCsat: number
  knowledge: number
  solution: number
  surveyResponses: number
  responseRate: number
  enoughVolume: boolean
  avgAptitude: number
  avgManner: number
  avgKnowledge: number
  avgSolution: number
  avgCoworkerCsat: number
  maxAptitude: number
  maxManner: number
  maxKnowledge: number
  maxSolution: number
  maxCoworkerCsat: number
}

export type SkillData = {
  skill: string
  skillExperience: number
  department: Department
  enoughVolumeR30: boolean
  volumeR30: number
  aht: AverageHandleTime
  repeatContact: RepeatContact | null
  resolutionRecommendation: Recommendation | null
  salesRoleRecommendation: Recommendation | null
  aov: AOV | null
  conversion: Conversion | null
  salesRecommendation: SalesRecommendation | null
}

export enum Department {
  Resolution = 'Resolution',
  Sales = 'Sales',
}

export enum Focus {
  AHT = 'AHT',
  RepeatContacts = 'Repeat Contacts',
  Conversion = 'Conversion',
  AOV = 'AOV',
}

export type AverageHandleTime = {
  ahtLast30IxnR90days: AHTLast30IxnR90days[]
  ahtR30: Rolling30AHT
  ahtTrendWeek: AhtTrendWeek[]
}

export type AhtTrendWeek = {
  avgHoldTime: number
  avgTalkTime: number
  aht: number
  avgAcwTime: number
  period: string
  volume: number
}

export type AHTLast30IxnR90days = {
  acwR30: number
  ahtR30: number
  dateLoc: string
  holdR30: number
  talkR30: number
}

export type Rolling30AHT = {
  aht: string
  potentialAht: string
  avgAht: string
  minAht: string
  enoughVolume: boolean
  impact: number
  impactRate: number
  avgTalkTime: number
  avgHoldTime: number
  avgAcwTime: number
}

export type RepeatContact = {
  repeatR30: Rolling30Repeat
  repeatLast30IxnR90days: RepeatLast30IxnR90days[]
  repeatTrendWeek: RepeatTrendWeek[]
}

export type RepeatTrendWeek = {
  repeatShare: number
  period: string
  volume: number
}

export type RepeatLast30IxnR90days = {
  repeatShare: number
  dateLoc: string
}

export type Rolling30Repeat = {
  enoughVolume: boolean
  avgRepeatShare: number | null
  minRepeatShare: number
  impact: number
  impactRate: number
  repeatShare: number
  potentialRepeatShare: number
}

export type Recommendation = {
  recommendation: string
  recommendationContact: string
  strength: string
}

/** AOV: Average order value */
export type AOV = {
  aovR30: Rolling30AOV
  aovTrendWeek: AovTrendWeek[]
}

export type Rolling30AOV = {
  enoughVolume: boolean
  aov: number
  avgAov: number
  maxAov: number
  currencyCode: string
}

export type AovTrendWeek = {
  period: string
  visitors: number
  aov: number
}

/** Conversion */
export type Conversion = {
  conversionR30: Rolling30Conversion
  conversionTrendWeek: ConversionTrendWeek[]
}

export type Rolling30Conversion = {
  enoughVolume: boolean
  conversionRate: number
  avgConversionRate: number
  maxConversionRate: number
}

export type ConversionTrendWeek = {
  period: string
  visitors: number
  conversionRate: number
}

/** Sales recommendation */
export type SalesRecommendation = {
  recommendation: string
  strength: string
}
