import styled from 'styled-components'

const FullscreenCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`

export default FullscreenCenter
