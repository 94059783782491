import { LoadingBall } from '@ingka/loading'
import Text from '@ingka/text'
import { Grid } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'

import { useLastCompletedCoachingSession } from 'hooks/coachingSessions'
import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useMissions } from 'state/slices/api'
import { useRecentQAs } from 'state/slices/api/modules/qualityAssessment/qualityAssessment.slice'

import { colourGreyNeutral4 } from 'styles/tokens/insikt.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import SubMetric from 'components/features/Efficiency/SubPanels/components/SubMetric.component'

const CoachingPanel = () => {
  const coworkerId = useCoworkerId()
  const { t } = useTranslation()
  const { data: lastCompletedCoachingSession, isLoading: isLoadingCoachingSessions } =
    useLastCompletedCoachingSession(coworkerId)
  const daysSinceLastSession = lastCompletedCoachingSession
    ? dayjs().diff(dayjs(lastCompletedCoachingSession?.completedDate), 'days')
    : 'N/A'
  const { data: currentAssessments, isLoading: isLoadingQas } = useRecentQAs(coworkerId)
  const { data: missions, isLoading: isLoadingMissions } = useMissions(coworkerId)

  const activeMissionsLength = missions?.filter(
    (mission) => mission.status === MissionStatus.Active
  ).length

  const isLoading = isLoadingCoachingSessions || isLoadingQas || isLoadingMissions

  return (
    <Widget border style={{ height: '100%' }}>
      <Text headingSize="m">{t('features.coworker-kpis.coaching-numbers.title')}</Text>
      <Text bodySize="l">{t('features.coworker-kpis.coaching-numbers.subtitle')}</Text>
      {isLoading ? (
        <LoadingBall />
      ) : (
        <Grid
          p={24}
          style={{
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: colourGreyNeutral4,
            marginTop: 24,
          }}
        >
          <Grid.Col span={4}>
            <SubMetric
              large
              description={t('features.coworker-kpis.coaching-numbers.days')}
              value={daysSinceLastSession}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <SubMetric
              large
              description={t('features.coworker-kpis.coaching-numbers.assessments')}
              value={String(currentAssessments?.QAs.length)}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <SubMetric
              large
              description={t('features.coworker-kpis.coaching-numbers.active-missions')}
              value={String(activeMissionsLength)}
            />
          </Grid.Col>
        </Grid>
      )}
    </Widget>
  )
}

export default CoachingPanel
