import styled from 'styled-components'

export const KonamiImage = styled.img<{ moveToRight?: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;

  font-size: 8rem;
  line-height: 1;
  z-index: 1000;
`
