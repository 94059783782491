import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const ConversionInfoBody = () => {
  const { t } = useTranslation()
  return (
    <>
      <Flex direction={'column'} gap={space50}>
        <Text headingSize="m">
          {t('features.1-efficiency.conversion-info.understanding-conversion')}
        </Text>

        <Text headingSize="s">{t('features.1-efficiency.conversion-info.what-is-conversion')}</Text>
        <Text bodySize="s">
          {t('features.1-efficiency.conversion-info.conversion-measures-percentage-inbound')}
        </Text>

        <Text headingSize="s">
          {t('features.1-efficiency.conversion-info.how-is-conversion-measured')}
        </Text>
        <Text bodySize="s">
          {t('features.1-efficiency.conversion-info.minimum-volume-requirement')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.at-least-60-interactions')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.low-data-volume-warning')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.smaller-sample-sizes')}
            </Text>
          </li>
        </ul>

        <Text headingSize="s">
          {t('features.1-efficiency.conversion-info.why-focus-on-individual-skills')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t(
                'features.1-efficiency.conversion-info.conversion-rates-calculated-specific-skills'
              )}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.only-inbound-contacts-assessed')}
            </Text>
          </li>
        </ul>

        <Text headingSize="s">{t('features.1-efficiency.conversion-info.benchmarks')}</Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.two-benchmark-values')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.average-conversion')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.highest-conversion')}
            </Text>
          </li>
        </ul>

        <Text headingSize="s">{t('features.1-efficiency.conversion-info.trends')}</Text>

        <Text headingSize="xs">
          {t('features.1-efficiency.conversion-info.long-term-performance-view')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.conversion-trends-weekly-breakdown')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.interpreting-fluctuations')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">
          {t('features.1-efficiency.conversion-info.why-weekly-trends-matter')}
        </Text>

        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.conversion-info.weekly-data-reliable-view')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.conversion-info.key-takeaway')}</Text>
      </Flex>
    </>
  )
}

export default ConversionInfoBody
