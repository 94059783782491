import Text from '@ingka/text'
import { Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { spacing50, spacing75 } from 'styles/tokens/insikt.tokens'

import { InfoParagraphBox } from 'components/primitives/InfoParagraphBox'
import { Heading } from 'components/primitives/Text'

type FAQProps = {
  namespace: string
}

type QuestionAnswerObject = {
  q: string
  a: string
  items?: string[]
}

type contentFAQProps = {
  title: string
  subtitle: string
  questions: QuestionAnswerObject[] | string
}

const FeatureInfoFAQ: React.FC<FAQProps> = ({ namespace }) => {
  const { t } = useTranslation()
  const pageType = useCurrentPageType()

  const rawContentFAQ = t(`${namespace}.faq.${pageType}`, { returnObjects: true })

  if (typeof rawContentFAQ !== 'object' || rawContentFAQ === null) {
    console.error(`FAQ data for ${namespace}.faq.${pageType} is invalid.`)
    return null
  }
  const contentFAQ = rawContentFAQ as contentFAQProps

  if (!Array.isArray(contentFAQ.questions)) {
    console.error(`FAQ questions for ${namespace}.faq.${pageType} are invalid.`)
    return null
  }
  return (
    <Stack gap={spacing50}>
      <Heading>{contentFAQ.title}</Heading>
      <Text bodySize="s">{contentFAQ.subtitle}</Text>
      {contentFAQ.questions?.map((questionObj, index) => (
        <FAQItem key={index} questionObj={questionObj} />
      ))}
    </Stack>
  )
}

type FAQItemProps = {
  questionObj: QuestionAnswerObject
}

const FAQItem: React.FC<FAQItemProps> = ({ questionObj }) => (
  <InfoParagraphBox>
    <Text headingSize="xs">{questionObj.q}</Text>
    {questionObj.a && (
      <Text bodySize="s" style={{ marginBottom: spacing50 }}>
        {questionObj.a}
      </Text>
    )}
    <Text bodySize="s">
      {questionObj.items && (
        <ul style={{ marginLeft: spacing75 }}>
          {questionObj.items.map((listItem, index) =>
            typeof listItem === 'object' && listItem !== null ? (
              // Render object list items with key-value formatting
              <li key={`item-${index}`}>
                <strong>{Object.keys(listItem)[0]}: </strong>
                {String(Object.values(listItem)[0])}
              </li>
            ) : (
              <li key={`item-${index}`}>{listItem}</li>
            )
          )}
        </ul>
      )}
    </Text>
  </InfoParagraphBox>
)

export default FeatureInfoFAQ
