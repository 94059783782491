import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { spacing75 } from 'styles/tokens/insikt.tokens'

const AHTInfoBody = () => {
  const { t } = useTranslation()
  return (
    <>
      <Flex direction={'column'} gap={space50}>
        <Text headingSize="m">
          {t('features.1-efficiency.aht-info.understanding-average-handle-time')}
        </Text>

        <Text headingSize="s">{t('features.1-efficiency.aht-info.what-is-aht')}</Text>
        <Text bodySize="s">{t('features.1-efficiency.aht-info.aht-measures')}</Text>
        <Text headingSize="s">{t('features.1-efficiency.aht-info.why-it-matters')}</Text>
        <Text bodySize="s">
          {t('features.1-efficiency.aht-info.faster-responses-reduce-queue-times')}
        </Text>
        <Text bodySize="s">
          {t('features.1-efficiency.aht-info.speed-should-never-compromise-quality')}
        </Text>

        <Text headingSize="s">
          {t('features.1-efficiency.aht-info.how-is-aht-performance-measured')}
        </Text>
        <Text headingSize="xs">
          {t('features.1-efficiency.aht-info.minimum-volume-requirement')}
        </Text>
        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.at-least-30-interactions-required')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">{t('features.1-efficiency.aht-info.low-data-volume-warning')}</Text>
          </li>
        </ul>
        <Text headingSize="xs">
          {t('features.1-efficiency.aht-info.skill-specific-calculations')}
        </Text>
        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.aht-is-calculated-for-specific-skills')}
            </Text>
          </li>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.fair-comparisons-across-similar-inquiries')}
            </Text>
          </li>
        </ul>
        <Text headingSize="s">{t('features.1-efficiency.aht-info.what-is-excluded-from-aht')}</Text>
        <Text headingSize="xs">
          {t('features.1-efficiency.aht-info.transferred-interactions-excluded')}
        </Text>
        <ul>
          <li>
            <Text bodySize="m">
              {t('features.1-efficiency.aht-info.efficiency-in-resolving-issues')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.aht-info.chat-aht')}</Text>
        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.multiple-chats-effective-time-spent')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.aht-info.benchmarks-for-aht')}</Text>
        <Text headingSize="xs">{t('features.1-efficiency.aht-info.average-aht')}</Text>
        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.average-handle-time-across-country')}
            </Text>
          </li>
        </ul>
        <ul>
          <Text headingSize="xs">{t('features.1-efficiency.aht-info.lowest-aht')}</Text>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.shortest-average-handle-time')}
            </Text>
          </li>
          <ul style={{ marginLeft: spacing75 }}>
            <li>
              <Text bodySize="s">
                {t('features.1-efficiency.aht-info.handled-at-least-30-interactions')}
              </Text>
            </li>
            <li>
              <Text bodySize="s">
                {t('features.1-efficiency.aht-info.maintained-happy-customer-score')}
              </Text>
            </li>
          </ul>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.aht-info.tracking-aht-trends')}</Text>
        <Text headingSize="xs">{t('features.1-efficiency.aht-info.long-term-view')}</Text>
        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.weekly-breakdowns-provide-insights')}
            </Text>
          </li>
        </ul>
        <Text headingSize="xs">
          {t('features.1-efficiency.aht-info.interpreting-fluctuations')}
        </Text>
        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.large-variations-may-indicate-insufficient-data')}
            </Text>
          </li>
        </ul>

        <Text headingSize="xs">{t('features.1-efficiency.aht-info.why-weekly-trends-matter')}</Text>
        <ul>
          <li>
            <Text bodySize="s">
              {t('features.1-efficiency.aht-info.weekly-data-reliable-snapshot')}
            </Text>
          </li>
        </ul>
        <Text bodySize="s">{t('features.1-efficiency.aht-info.hover-over-trend-lines')}</Text>
      </Flex>
    </>
  )
}

export default AHTInfoBody
