import { useTranslation } from 'react-i18next'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'

const RedirectingLayout: React.FC = () => {
  const { t } = useTranslation()

  return <LoadingIndicator message={t('loading-messages.redirecting')} />
}

export default RedirectingLayout
