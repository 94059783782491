import Text from '@ingka/text'
import { Flex } from '@mantine/core'

interface Props {
  value: number | string
  description: string
  color?: string
  large?: boolean
  topText?: string
}

const SubMetric = ({ value, description, color, large, topText }: Props) => {
  return (
    <Flex direction={'row'} p={8} gap={24} align={'center'} h={82}>
      <div
        style={{
          backgroundColor: color ? color : '#A3ACB0',
          height: '100%',
          width: 8,
        }}
      />
      <Flex direction={'column'}>
        <Text bodySize="s">{topText}</Text>
        <Text headingSize={large ? 'l' : 'xs'}>{value}</Text>
        <Text bodySize="s">{description}</Text>
      </Flex>
    </Flex>
  )
}

export default SubMetric
