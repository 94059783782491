export const qasGroups = {
  QASOP: {
    id: 'QASOP',
    order: 0,
    questions: {
      QAS01: {
        id: 'QAS01',
        order: 0,
      },
      QAS02: {
        id: 'QAS02',
        order: 1,
      },

      QAS03: {
        id: 'QAS03',
        order: 2,
      },
      QAS04: {
        id: 'QAS04',
        order: 2,
      },
    },
  },
  QASSO: {
    id: 'QASSO',
    order: 1,
    questions: {
      QAS05: {
        id: 'QAS05',
        order: 0,
      },

      QAS06: {
        id: 'QAS06',
        order: 1,
      },

      QAS07: {
        id: 'QAS07',
        order: 2,
      },
    },
  },
  QASBI: {
    id: 'QASBI',
    order: 2,
    questions: {
      QAS08: {
        id: 'QAS08',
        order: 0,
      },
      QAS09: {
        id: 'QAS09',
        order: 1,
      },

      QAS10: {
        id: 'QAS10',
        order: 2,
      },
    },
  },
  QASEN: {
    id: 'QASEN',
    order: 3,
    questions: {
      QAS11: {
        id: 'QAS11',
        order: 0,
      },
    },
  },
}

export type QasTotalKey = 'QASTT'
export type QasGroupKey = keyof typeof qasGroups
export type QasQuestionKey =
  | keyof (typeof qasGroups)['QASOP']['questions']
  | keyof (typeof qasGroups)['QASSO']['questions']
  | keyof (typeof qasGroups)['QASBI']['questions']
  | keyof (typeof qasGroups)['QASEN']['questions']
export type QasKey = QasTotalKey | QasGroupKey | QasQuestionKey

export const isQasGroupKey = (id: string): id is QasGroupKey =>
  Object.keys(qasGroups).includes(id)

export const isQasQuestionKey = (id: string): id is QasQuestionKey =>
  Object.keys(qasGroups.QASOP.questions).includes(id) ||
  Object.keys(qasGroups.QASSO.questions).includes(id) ||
  Object.keys(qasGroups.QASBI.questions).includes(id) ||
  Object.keys(qasGroups.QASEN.questions).includes(id)

export const isQasKey = (id: string): id is QasKey =>
  id === 'QASTT' || isQasGroupKey(id) || isQasQuestionKey(id)
