import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InlineMessage from '@ingka/inline-message'
import InputField from '@ingka/input-field'
import Modal, { ModalFooter, Sheets } from '@ingka/modal'
import Select, { Option } from '@ingka/select'
import Switch from '@ingka/switch'
import { Stack } from '@mantine/core'
import { Fragment, useState } from 'react'
import { Controller } from 'react-hook-form'

import { useCreateCountry } from 'state/slices/api/modules/country/country.slice'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { Widget, WidgetFooter } from 'components/composites/Shared/Widget'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useManageCountryForm, useSelectedCountry } from './CountryOverview.hooks'

type Country = {
  name: string
  code: string
  currency: string
  suggestResolutionFocus: boolean
  suggestSalesFocus: boolean
}

const CountryOverview: React.FC = () => {
  const [
    { countries, selectedCountry, isLoading, isSuccess, isError },
    { control: countrySelectionControl },
  ] = useSelectedCountry()

  const [showModal, setShowModal] = useState(false)
  const [countryToAdd, setCountryToAdd] = useState<Country>({
    name: '',
    code: '',
    currency: '',
    suggestResolutionFocus: false,
    suggestSalesFocus: false,
  })

  const [showCountryCodeWarning, setShowCountryCodeWarning] = useState(false)
  const [showCurrencyCodeWarning, setShowCurrencyCodeWarning] = useState(false)
  const [showNameWarning, setShowNameWarning] = useState(false)

  const [
    {
      control,
      reset,
      handleSubmit,
      formState: { isDirty, isValid },
    },
    { isLoading: isUpdatingSettings },
  ] = useManageCountryForm(selectedCountry)


  const [createCountry] = useCreateCountry()

  const sendCountry = async () => {
    await createCountry({
      countryCode: countryToAdd.code,
      value: {
        countryName: countryToAdd.name,
        currencyCode: countryToAdd.currency,
        suggestResolutionFocus: countryToAdd.suggestResolutionFocus,
        suggestSalesFocus: countryToAdd.suggestSalesFocus,
      },
    })
    setShowModal(false)
  }

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target.value ?? '').toUpperCase()

    const regex = /^[A-Z]{2}$/g

    if (!value || !regex.test(value)) {
      setShowCountryCodeWarning(true)
    } else {
      setShowCountryCodeWarning(false)
    }

    setCountryToAdd((prev) => ({
      ...prev,
      code: value,
    }))
  }

  const handleCurrencyCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target.value ?? '').toUpperCase()

    const regex = /^[A-Z]{3}$/g

    if (!value || !regex.test(value)) {
      setShowCurrencyCodeWarning(true)
    } else {
      setShowCurrencyCodeWarning(false)
    }

    setCountryToAdd((prev) => ({
      ...prev,
      currency: value,
    }))
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ?? ''

    const regex = /^[A-Z][a-z -]{1,100}$/g

    if (!value || !regex.test(value)) {
      setShowNameWarning(true)
    } else {
      setShowNameWarning(false)
    }

    setCountryToAdd((prev) => ({
      ...prev,
      name: value,
    }))
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Widget
          footer={
            <WidgetFooter>
              <Button
                type="primary"
                disabled={!isDirty || !isValid || isUpdatingSettings}
                htmlType="submit"
              >
                {'Save changes'}
              </Button>

              <Button disabled={!isDirty || isUpdatingSettings} onClick={() => reset()}>
                {'Reset'}
              </Button>
            </WidgetFooter>
          }
        >
          {isLoading && <LoadingIndicator height="400px" />}
          {isError && <UnexpectedErrorMessage />}
          {isSuccess && (
            <Stack>
              <Controller
                name="selectedCountryId"
                control={countrySelectionControl}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField>
                    <Select
                      label="Country"
                      id="country"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    >
                      {countries?.map((country) => (
                        <Option
                          key={country.countryCode}
                          value={country.countryCode}
                          label={country.name}
                        />
                      ))}
                    </Select>
                  </FormField>
                )}
              />
              {selectedCountry && (
                <Stack>
                  <Controller
                    name="isAppEnabled"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormField>
                        <Switch
                          label="Enable Insikt"
                          id="isAppEnabled"
                          value="isAppEnabled"
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name="suggestResolutionFocus"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormField>
                        <Switch
                          label="Suggest Resolution Focus"
                          id="suggestResolutionFocus"
                          value="suggestResolutionFocus"
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name="suggestSalesFocus"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormField>
                        <Switch
                          label="Suggest Sales Focus"
                          id="suggestSalesFocus"
                          value="suggestSalesFocus"
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      </FormField>
                    )}
                  />
                </Stack>
              )}
              {!selectedCountry && (
                <InlineMessage
                  variant="informative"
                  title="Please select a country from the list."
                />
              )}
            </Stack>
          )}
        </Widget>
        <Widget>
          <Button onClick={() => setShowModal(true)}>{'Add country'}</Button>
        </Widget>
      </form>
      <Modal visible={showModal} handleCloseBtn={() => setShowModal(false)}>
        <Sheets
          aria-label="Add country modal"
          footer={
            <ModalFooter>
              <Fragment key=".0">
                <Button text={'Cancel'} type="danger" onClick={() => setShowModal(false)} />
                <Button
                  text="Create Country"
                  type="primary"
                  disabled={!countryToAdd.name || !countryToAdd.currency || !countryToAdd.code}
                  onClick={sendCountry}
                />
              </Fragment>
            </ModalFooter>
          }
          header={null}
        >
          <div style={{ margin: '10px' }}>
            <FormField
              fieldHelper={{
                id: 'helper-msg-name',
                msg: 'Add the country name',
                type: '',
              }}
              valid="warning"
              shouldValidate={showNameWarning}
              validation={{
                id: 'error-msg-id',
                msg: 'Invalid country name - it has to starts with capital letter, only letters',
                type: 'warning',
              }}
            >
              <InputField
                id="Name"
                type="text"
                label="Country Name"
                onChange={handleNameChange}
                value={countryToAdd.name}
              />
            </FormField>

            <FormField
              fieldHelper={{
                id: 'helper-msg-code',
                msg: 'Add the 2 letter country code',
                type: '',
              }}
              valid="warning"
              shouldValidate={showCountryCodeWarning}
              validation={{
                id: 'error-msg-id',
                msg: 'Invalid country code - only 2 non-digit char in upper case',
                type: 'warning',
              }}
            >
              <InputField
                id="Code"
                type="text"
                label="Country Code"
                onChange={handleCountryCodeChange}
                value={countryToAdd.code}
              />
            </FormField>
            <FormField
              fieldHelper={{
                id: 'helper-msg-code',
                msg: 'Add the 3 letter currency code',
                type: '',
              }}
              valid="warning"
              shouldValidate={showCurrencyCodeWarning}
              validation={{
                id: 'error-msg-id',
                msg: 'Invalid currency code - only 3 non-digit char in upper case',
                type: 'warning',
              }}
            >
              <InputField
                id="Currency"
                type="text"
                label="Currency Code"
                onChange={handleCurrencyCodeChange}
                value={countryToAdd.currency}
              />
            </FormField>
          </div>
        </Sheets>
      </Modal>
    </>
  )
}

export default CountryOverview
