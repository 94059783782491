import SSRIcon from '@ingka/ssr-icon'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Text from '@ingka/text'
import { space25, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AOV, Focus, SkillData } from 'lib/types/coworkerData.types'
import { formatMoney } from 'lib/utils/formatMoney.utils'

import { colourBrandYellow, colourSemanticCaution } from 'styles/tokens/oldSkapa.tokens'

import AOVInfoBody from './components/AOVInfoBody.component'
import MainMetric from './components/MainMetric.component'
import AovGraph from 'components/composites/Graphs/aovGraph.component'
import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import InfoPanel from 'components/composites/Shared/InfoPanel/InfoPanel.component'

import { useSuggestedFocus } from '../useSuggestedFocus.hook'
import SubPanelLayout from './SubPanel.layout'

interface Props {
  data: SkillData
}

const AovSubPanel = ({ data }: Props) => {
  const { t } = useTranslation()

  if (!data.aov) {
    return (
      <SubPanelLayout
        leftPanel={
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('No data available')}
          </Text>
        }
        rightPanel={
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('No data available')}
          </Text>
        }
      />
    )
  }

  return (
    <SubPanelLayout
      leftPanel={<AovLeftPanel data={data} />}
      rightPanel={<AovRightPanel data={data.aov} />}
    />
  )
}

const AovLeftPanel = ({ data }: { data: SkillData }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const suggestedFocusOpacity = useSuggestedFocus(Focus.AOV, data)
  const showTopPart = !data.aov || !data.aov.aovR30.enoughVolume || suggestedFocusOpacity === 1

  return (
    <Flex direction={'column'} gap={space100} w={'100%'} justify={'space-evenly'}>
      {showTopPart && (
        <Flex justify={'space-between'}>
          <Text
            headingSize="s"
            style={{
              backgroundColor: colourBrandYellow,
              padding: space25,
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              opacity: suggestedFocusOpacity,
            }}
          >
            {t('features.1-efficiency.highest-potential')}
          </Text>
          {!data?.aov?.aovR30.enoughVolume && (
            <Text bodySize="s" style={{ textAlign: 'center' }}>
              <SSRIcon
                paths={warningTriangle}
                style={{ marginRight: space25 }}
                color={colourSemanticCaution}
              />
              {t('features.1-efficiency.low-data-volume')}
            </Text>
          )}
        </Flex>
      )}

      <Flex justify={'space-between'}>
        <Flex direction={'column'}>
          <Text headingSize="m">{t('features.1-efficiency.your-now', { metric: 'ACV' })}</Text>
          <Text bodySize="s">{t('features.1-efficiency.your-now-subtitle')}</Text>
        </Flex>
        <InfoPanel
          body={<AOVInfoBody />}
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          handleOpen={() => setShowModal(true)}
        />
      </Flex>

      <Flex style={{ opacity: data?.aov?.aovR30.enoughVolume ? 1 : 0.5 }}>
        <MainMetric
          value={formatMoney(data?.aov?.aovR30.aov, data?.aov?.aovR30.currencyCode)}
          avg={formatMoney(data?.aov?.aovR30.avgAov, data?.aov?.aovR30.currencyCode)}
          top={formatMoney(data?.aov?.aovR30.maxAov, data?.aov?.aovR30.currencyCode)}
          unit=""
          color="#ee8310"
          topPerformerText={'features.1-efficiency.country-highest'}
        />
      </Flex>
    </Flex>
  )
}

const AovRightPanel = ({ data }: { data: AOV }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      {data.aovTrendWeek.length > 0 ? (
        <>
          <Text headingSize="m">{t('features.1-efficiency.your-trend', { metric: 'ACV' })}</Text>
          <Text bodySize="s">
            {t('features.1-efficiency.your-trend-subtitle', { metric: 'ACV' })}
          </Text>

          <Flex w={'100%'} h={300} style={{ marginTop: space100 }}>
            <AovGraph data={data} />
          </Flex>
        </>
      ) : (
        <EmptyContent translationKey="features.1-efficiency.no-data-available" />
      )}
    </Flex>
  )
}

export default AovSubPanel
