import { QaMetricId } from 'config/domain/qa.config'

type MetricConfig = {
  /** Unique identifier for the metric.
   *
   * Used in the backend and for translation keys
   */
  id: OperationalMetricId | 'qa'
  /** English name for the metric. */
  name: string
  /** Specifies how the metric is visualised */
  metricBehaviour: MetricsBehaviourConfiguration
  /** Specifies where the metric may be used */
  allowedUses: MetricUseConfiguration
}

type MetricsBehaviourConfiguration = {
  /** The minimum value this metric can have. */
  min?: number
  /** The maximum value this metric can have. */
  max?: number
  /** If true, indicates that a lower score is better. */
  inverseScore?: boolean
  /* If true, indicates that the trend value should not be used */
  noTrendData?: boolean
  /** Specifies unit of the metric */
  unit?: MetricUnit
  /** Specifies how many decimals the metric is shown with */
  decimalPlaces: 0 | 1 | 2
  /** Specifies that the metric is only available on a co-worker level */
  noTeamData?: boolean
  /** Specifies if the metric has VAT (tax) data to also be shown along with the data */
  includesVat?: boolean
}

type MetricUseConfiguration = {
  missions?: boolean
  explore?: boolean
}

/**
 * Constants used across metric configurations
 */
const MONETARY_DECIMAL_PLACES = 2

type MetricUnit = 'monetary' | 'percentage'

export type OperationalMetricId =
  | 'aptitude_score'
  | 'average_csat'
  | 'average_manner'
  | 'average_knowledge'
  | 'average_solution_quality'
  | 'positive_csat_streak'
  | 'aov'
  | 'aov_excl_vat'
  | 'aov_rank'
  | 'sales_gross'
  | 'sales_gross_excl_vat'
  | 'conversion_rate'
  | 'sales_item_quantity'
  | 'sales_item_price_avg'
  | 'sales_item_price_avg_excl_vat'
  | 'survey_count'
  | 'survey_resp_rate'
  | 'positive_csat_rate'

export type MetricId = OperationalMetricId | QaMetricId

export const isMetricId = (value: string): value is OperationalMetricId =>
  Object.keys(metricsConfig).includes(value)

export const qualityAssessmentConfig: MetricConfig = {
  id: 'qa',
  name: 'Quality Assessment Question',
  metricBehaviour: {
    min: 0,
    max: 100,
    decimalPlaces: 0,
  },
  allowedUses: {},
}

export const metricsConfig: Record<OperationalMetricId, MetricConfig> = {
  aptitude_score: {
    id: 'aptitude_score',
    name: 'Aptitude Score',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      missions: true,
      explore: true, // Adding to Explore page for testing purposes
    },
  },
  average_csat: {
    id: 'average_csat',
    name: 'Average CSAT',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      explore: true,
    },
  },
  average_manner: {
    id: 'average_manner',
    name: 'Average Manner',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      explore: true,
    },
  },
  average_knowledge: {
    id: 'average_knowledge',
    name: 'Average Knowledge',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      explore: true,
    },
  },
  average_solution_quality: {
    id: 'average_solution_quality',
    name: 'Average Solution Quality',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      explore: true,
    },
  },
  positive_csat_streak: {
    id: 'positive_csat_streak',
    name: 'Positive CSAT Streak',
    metricBehaviour: {
      min: 0,
      noTrendData: true,
      decimalPlaces: 0,
    },
    allowedUses: {},
  },
  aov: {
    id: 'aov',
    name: 'Average Order Value',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
      includesVat: true,
    },
    allowedUses: {
      explore: true,
    },
  },
  aov_excl_vat: {
    id: 'aov_excl_vat',
    name: 'Average Order Value excl. VAT',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
    },
    allowedUses: {},
  },
  aov_rank: {
    id: 'aov_rank',
    name: 'AOV Rank in Team',
    metricBehaviour: {
      min: 1,
      inverseScore: true,
      noTrendData: true,
      decimalPlaces: 0,
      noTeamData: true,
    },
    allowedUses: {
      explore: true,
    },
  },
  sales_gross: {
    id: 'sales_gross',
    name: 'Total gross sales',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
      includesVat: true,
    },
    allowedUses: {
      explore: true,
      missions: true,
    },
  },
  sales_gross_excl_vat: {
    id: 'sales_gross_excl_vat',
    name: 'Total gross sales',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
    },
    allowedUses: {},
  },
  conversion_rate: {
    id: 'conversion_rate',
    name: 'Conversion rate',
    metricBehaviour: {
      min: 0,
      max: 1,
      unit: 'percentage',
      decimalPlaces: 0,
    },
    allowedUses: {},
  },
  sales_item_quantity: {
    id: 'sales_item_quantity',
    name: 'Items per transaction',
    metricBehaviour: {
      min: 0,
      decimalPlaces: 0,
    },
    allowedUses: {
      explore: true,
    },
  },
  sales_item_price_avg: {
    id: 'sales_item_price_avg',
    name: 'Average item price',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
      includesVat: true,
    },
    allowedUses: {},
  },
  sales_item_price_avg_excl_vat: {
    id: 'sales_item_price_avg_excl_vat',
    name: 'Average item price',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
    },
    allowedUses: {},
  },
  survey_count: {
    id: 'survey_count',
    name: 'Number of Surveys',
    metricBehaviour: {
      min: 0,
      decimalPlaces: 0,
      noTeamData: true,
    },
    allowedUses: {
      explore: true,
    },
  },
  survey_resp_rate: {
    id: 'survey_resp_rate',
    name: 'Survey response rate',
    metricBehaviour: {
      min: 0,
      max: 1,
      decimalPlaces: 0,
      unit: 'percentage',
    },
    allowedUses: {
      explore: true,
    },
  },
  positive_csat_rate: {
    id: 'positive_csat_rate',
    name: 'Positive CSAT Rate',
    metricBehaviour: {
      min: 0,
      max: 1,
      decimalPlaces: 0,
      unit: 'percentage',
    },
    allowedUses: {
      explore: true,
    },
  },
}

export const operationalMetricIds = Object.keys(metricsConfig) as OperationalMetricId[]

/** Shortened list of connected metrics for missions  */
export type ConnectedMetricIdForMissions =
  | 'aptitude_score'
  | 'sales_gross'
  | 'aov'
  | 'conversion_rate'
  | 'average_manner'
  | 'average_knowledge'
  | 'average_solution_quality'

export const connectedMetricsConfig: Record<ConnectedMetricIdForMissions, MetricConfig> = {
  aptitude_score: {
    id: 'aptitude_score',
    name: 'CSAT aptitude',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      missions: true,
      explore: true,
    },
  },
  sales_gross: {
    id: 'sales_gross',
    name: 'Total gross sales',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
      includesVat: true,
    },
    allowedUses: {
      explore: true,
      missions: true,
    },
  },
  aov: {
    id: 'aov',
    name: 'Average Order Value',
    metricBehaviour: {
      min: 0,
      unit: 'monetary',
      decimalPlaces: MONETARY_DECIMAL_PLACES,
      includesVat: true,
    },
    allowedUses: {
      missions: true,
      explore: true,
    },
  },
  conversion_rate: {
    id: 'conversion_rate',
    name: 'Conversion rate',
    metricBehaviour: {
      min: 0,
      max: 1,
      unit: 'percentage',
      decimalPlaces: 0,
    },
    allowedUses: {
      missions: true,
      explore: true,
    },
  },
  average_manner: {
    id: 'average_manner',
    name: 'Average Manner',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      missions: true,
      explore: true,
    },
  },
  average_knowledge: {
    id: 'average_knowledge',
    name: 'Average Knowledge',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      missions: true,
      explore: true,
    },
  },
  average_solution_quality: {
    id: 'average_solution_quality',
    name: 'Average Solution Quality',
    metricBehaviour: {
      min: 1,
      max: 5,
      decimalPlaces: 1,
    },
    allowedUses: {
      missions: true,
      explore: true,
    },
  },
}

export const connectedMetricIdsForMissions = Object.keys(
  connectedMetricsConfig
) as ConnectedMetricIdForMissions[]
