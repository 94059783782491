import SSRIcon from '@ingka/ssr-icon'
import clipboardPencil from '@ingka/ssr-icon/paths/clipboard-pencil'
import InformationCircle from '@ingka/ssr-icon/paths/information-circle'
import Text from '@ingka/text'
import { space100 } from '@ingka/variables'
import { Accordion, Box, Grid, Group, Skeleton, Stack } from '@mantine/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Mission } from 'lib/types/mission.types'

import { colourGreyNeutral6, radius25, spacing50, spacing75, spacing100 } from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import { MissionCoachingPointersFormatted } from 'components/composites/Missions/MissionCoachingPointersFormatted'
import { MissionPreview } from 'components/composites/Missions/MissionPreview'
import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import { GridLayout } from 'components/composites/Shared/GridLayout/GridLayout.component'
import { GridVariant } from 'components/composites/Shared/GridLayout/GridLayout.types'
import { Widget } from 'components/composites/Shared/Widget'
import CompleteMissionButton from 'components/features/Missions/CompleteMissionButton/CompleteMissionButton.component'
import { SubHeading } from 'components/primitives/Text'

import { useMissionMonthSplit } from './MissionsOverview.hooks'

type MissionOverviewProps = {
  missions: Mission[]
  header?: boolean
  isLoading: boolean
  completed?: boolean
}

// TODO: Maybe move this header? Do we want it in any other place than Upcomming coaching session?
// TODO: We could simplify this with nice returns if not for this header - definetly think about something there
export const MissionsOverview: React.FC<MissionOverviewProps> = ({
  missions,
  isLoading,
  completed = false,
}) => {
  if (isLoading) {
    return <MissionWithPointersSkeleton />
  }

  if (missions.length === 0) {
    // Select the most relevant translation to show the user
    const missingMissionsKey = completed
      ? 'features.mission.warnings.no-completed'
      : 'features.mission.warnings.no-data'
    return (
      <>
        <EmptyContent translationKey={missingMissionsKey} />
      </>
    )
  }

  if (completed) {
    return <CompletedMissionsWithMonthSplit missions={missions} />
  }
  return <MissionWithPointers missions={missions} />
}

const CompletedMissionsWithMonthSplit = ({ missions }: { missions: Mission[] }) => {
  const splitMonths = useMissionMonthSplit(missions)
  const { t } = useTranslation()
  return (
    <>
      {splitMonths.map((month, index) => (
        <Accordion
          key={index}
          defaultValue="0"
          className="accordion-split-missions"
          mb={spacing100}
        >
          <Accordion.Item value={'' + index}>
            <Accordion.Control
              style={{
                backgroundColor: 'white',
                borderRadius: radius25,
                fontSize: '1.2rem',
              }}
            >
              <b>{`${t(`common.months.${month.month}`)} - ${month.year}`}</b>
            </Accordion.Control>
            <Accordion.Panel mt={spacing100}>
              <MissionWithPointers missions={month.missions} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      ))}
    </>
  )
}

const MissionWithPointers = ({ missions }: { missions: Mission[] }) => {
  const { coworkerId, teamleadId } = useParams()

  if (!coworkerId) {
    return null
  }

  return (
    <>
      {missions.map((mission, index) => (
        <Fragment key={index}>
          <Widget border style={{ marginBottom: spacing100, border: `1px dashed ${colourGreyNeutral6}` }}>
            {/* {missions.length > 1 && <SubHeading>{'Mission ' + (index + 1)}</SubHeading>} */}
            <GridLayout
              padding={0}
              noDivider
              gridStyles={{
                marginTop: '0',
                backgroundColor: 'transparent',
                marginBottom: spacing100,
              }}
              key={mission.id}
              children={[
                {
                  element: <MissionPreview mission={mission} coworkerId={coworkerId} />,
                  variant: GridVariant.OneSidedSmall,
                },
                {
                  element: <CoachingPointersOverview coachingPointers={mission.coachingPointers} />,
                  variant: GridVariant.OneSidedLarge,
                },
              ]}
            />
            {teamleadId && (
              <Widget border>
                <Text style={{ marginBottom: space100 }}>
                  <SSRIcon paths={InformationCircle} />{' '}
                  {
                    'Suggesting to complete the mission will mark it as done and it will be moved to the completed missions list when the upcoming coaching session is finished.'
                  }
                </Text>
                <CompleteMissionButton mission={mission} coworkerId={coworkerId} />
              </Widget>
            )}
          </Widget>
        </Fragment>
      ))}
    </>
  )
}

const MissionWithPointersSkeleton = () => (
  <Grid>
    <GridLayout
      padding={0}
      children={[
        {
          element: <Skeleton height={'45vh'} />,
          variant: GridVariant.OneSidedSmall,
        },
        {
          element: <Skeleton height={'45vh'} />,
          variant: GridVariant.OneSidedLarge,
        },
      ]}
    />
  </Grid>
)

type CoachingPointersOverviewProps = {
  coachingPointers: string
}

const CoachingPointersOverview = ({ coachingPointers }: CoachingPointersOverviewProps) => {
  return (
    <Widget border style={{ height: '100%' }}>
      <Stack gap={spacing50} style={{ height: '100%' }}>
        <Header />
        <Box
          style={{
            backgroundColor: colourNeutralGrey100,
            padding: spacing75,
            borderRadius: radius25,
            height: '100%',
          }}
        >
          <MissionCoachingPointersFormatted coachingPointers={coachingPointers} />
        </Box>
      </Stack>
    </Widget>
  )
}

const Header = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Group mb={spacing50} gap={spacing50}>
        <SSRIcon paths={clipboardPencil} />
        <SubHeading>{t('features.coaching-session.coworker.coaching-pointers.title')}</SubHeading>
      </Group>
      <Text>{t('features.coaching-session.coworker.coaching-pointers.subtitle')}</Text>
    </Box>
  )
}
