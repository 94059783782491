import { CustomerComment } from 'lib/types/customerComment.types'

import { apiSlice } from 'state/slices/api/api.slice'

export type ForwardOrDismissCommentArgs = {
  coworkerId: string
  interactionId: string
}

export const customerCommentTeamleadSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerComments: builder.query<CustomerComment[], string>({
      query: (coworkerId) => `/tl/coworkers/${coworkerId}/customerComments`,
      providesTags: (result, error, coworkerId) => [{ type: 'Customer Comment', id: coworkerId }],
    }),
    forwardCustomerComment: builder.mutation<void, ForwardOrDismissCommentArgs>({
      query: ({ coworkerId, interactionId }) => ({
        url: `/tl/coworkers/${coworkerId}/customerComments/${interactionId}/forward`,
        method: 'POST',
      }),
      // Do not refetch customer comments list after forwarding/dismissing a comment
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        await queryFulfilled
        // Only after success, remove the comment from the cache
        const { coworkerId, interactionId } = data
        dispatch(
          customerCommentTeamleadSlice.util.updateQueryData(
            'getCustomerComments',
            coworkerId,
            (draft) => {
              const index = draft.findIndex((comment) => comment.interactionId === interactionId)
              if (index !== -1) {
                draft[index].status = 'forwarded'
                draft.splice(index, 1)
              }
            }
          )
        )
      },
      invalidatesTags: [],
    }),
    dismissCustomerComment: builder.mutation<void, ForwardOrDismissCommentArgs>({
      query: ({ coworkerId, interactionId }) => ({
        url: `/tl/coworkers/${coworkerId}/customerComments/${interactionId}/dismiss`,
        method: 'POST',
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            customerCommentTeamleadSlice.util.updateQueryData(
              'getCustomerComments',
              data.coworkerId,
              (draft) => {
                const index = draft.findIndex(
                  (comment) => comment.interactionId === data.interactionId
                )
                if (index !== -1) {
                  draft[index].status = 'dismissed'
                  draft.splice(index, 1)
                }
              }
            )
          )
        } catch (error) {
          console.error(`Error in teamlead dismissing comment: ${error}`)
        }
      },
      invalidatesTags: [],
    }),
  }),
})

export const {
  useGetCustomerCommentsQuery: useGetTeamleadCustomerComments,
  useForwardCustomerCommentMutation: useForwardTeamleadCustomerComment,
  useDismissCustomerCommentMutation: useDismissTeamleadCustomerComment,
} = customerCommentTeamleadSlice
