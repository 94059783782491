import { apiSlice } from 'state/slices/api/api.slice'

type UpdateUserFixtureParams = {
  edited?: boolean
  deleted?: boolean
} & UserFixtureResponse

type UserFixtureResponse = {
  legacyId: string
  role: string
  emailUsername: string
  country: string
}

const userFixturesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchFixtures: builder.query<UserFixtureResponse[], void>({
      query: () => `users/user-fixtures`,
      providesTags: ['UserFixtures'],
    }),
    updateFixtures: builder.mutation<void, UpdateUserFixtureParams[]>({
      query: (params) => ({
        url: `users/user-fixtures`,
        method: 'PATCH',
        body: { params },
      }),
      invalidatesTags: ['UserFixtures'],
    }),
  }),
})

export const { useFetchFixturesQuery, useUpdateFixturesMutation } = userFixturesSlice
