import Tooltip from '@ingka/tooltip'
import { radiusS } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import { useState } from 'react'

import { MissionStatus } from 'lib/types/mission.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useMissions } from 'state/slices/api'

import {
  colourGreyNeutral1,
  colourGreyNeutral3,
  spacing50,
  spacing75,
  spacing100,
} from 'styles/tokens/insikt.tokens'

import InfoPanel from 'components/composites/Shared/InfoPanel/InfoPanel.component'
import { MissionsOverview } from 'components/features/CoworkerMVP/CoachingSession/MissionsOverview/MissionsOverview.component'
import FeatureInfoFAQ from 'components/features/FeatureInfoFAQ/FeatureInfoFAQ.component'
import { FilterMissionsButtons } from 'components/pages/shared/MissionsPage/MissionsActions/FilterMissionsButtons'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useAnalytics } from './MissionPreviewList.hooks'
import { MissionPreviewListProps } from './MissionPreviewList.types'

const MissionPreviewList: React.FC<MissionPreviewListProps> = ({ disableFilter = false }) => {
  const coworkerId = useCoworkerId()

  const { data: missions = [], isLoading, isError } = useMissions(coworkerId)

  const missionsStatus = [MissionStatus.Active, MissionStatus.Completed]
  const [selected, setSelected] = useState<MissionStatus>(MissionStatus.Active)
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false)

  useAnalytics(disableFilter, selected, coworkerId)

  const filteredMissions = missions.filter((mission) => mission.status === selected)

  if (isError) return <UnexpectedErrorMessage />

  return (
    <>
      <Flex justify={'flex-start'} my={spacing100}>
        <Flex
          align={'center'}
          bg={colourGreyNeutral1}
          w={'100%'}
          p={`${spacing75} ${spacing100} ${spacing75} ${spacing50}`}
          gap={spacing50}
          style={{ borderRadius: radiusS, borderBottom: `1px solid ${colourGreyNeutral3}` }}
        >
          <FilterMissionsButtons
            missionsStatus={missionsStatus}
            selected={selected}
            setSelected={setSelected}
          />
          <Box style={{ marginLeft: 'auto' }}>
            <Tooltip tooltipText={'Missions (FAQ)'}>
              <InfoPanel
                showModal={showInfoModal}
                handleClose={() => setShowInfoModal(false)}
                handleOpen={() => setShowInfoModal(true)}
                body={<FeatureInfoFAQ namespace="features.mission.info-panel" />}
              />
            </Tooltip>
          </Box>
        </Flex>
      </Flex>
      <MissionsOverview
        missions={filteredMissions}
        isLoading={isLoading}
        completed={selected === MissionStatus.Completed}
      />
    </>
  )
}

export default MissionPreviewList
