import Avatar from '@ingka/avatar'
import Button from '@ingka/button'
import SSRIcon from '@ingka/ssr-icon'
import person from '@ingka/ssr-icon/paths/person'
import questionMarkCircle from '@ingka/ssr-icon/paths/question-mark-circle'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Tooltip from '@ingka/tooltip'
import { space50, space75, space100, space300 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useUser } from 'hooks/users'

import { shadowDefaultSize } from 'styles/tokens/insikt.tokens'
import {
  colourBrandYellow,
  colourButtonPrimaryEmphasisedPress,
  colourSemanticPositive,
} from 'styles/tokens/oldSkapa.tokens'

import { FeedbackModal } from 'components/features/Feedback/FeedbackModal'
import { NotificationsButton } from 'components/features/General/NotificationsButton'
import { ShowOnlyOnLargerDevice } from 'components/layout/Helpers'
import { SideMenu } from 'components/layout/SideMenu'
import { Heading } from 'components/primitives/Text'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import { useWarningMessage } from './Header.hooks'
import * as Styled from './Header.styling'

const Header: React.FC<{ title?: string; backHref?: string }> = () => {
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const { showWarning, tooltipText } = useWarningMessage()
  const { data: user } = useUser()

  return (
    <>
      <Box bg={matchEnvColor(process.env.REACT_APP_ENV)} data-testid="header">
        {user && (
          <Flex
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            py={space75}
            px={{ xs: 0, sm: `calc(${space300} + ${space75})` }}
          >
            <ShowOnlyOnLargerDevice>
              <Flex align="center" gap={space100}>
                <Link
                  to="/"
                  style={{
                    textDecoration: 'none',
                    textAlign: 'center',
                    // Hack to get the text to be centered vertically with icon
                    height: '26px',
                    cursor: 'pointer',
                  }}
                >
                  <Heading style={{ color: colourBrandYellow }}>{'Insikt'}</Heading>
                </Link>
                {showWarning && (
                  <Tooltip tooltipText={tooltipText} position="bottom">
                    <SSRIcon paths={warningTriangle} color={colourBrandYellow} />
                  </Tooltip>
                )}
              </Flex>
            </ShowOnlyOnLargerDevice>

            <Flex align="center" gap={space50}>
              <Button
                data-testid="btn-show-contact-modal"
                type="tertiary"
                small
                iconOnly
                ssrIcon={questionMarkCircle}
                inverseTheme
                onClick={() => setShowFeedbackModal(true)}
              />

              <FeatureToggle featureKey="notifications">
                <NotificationsButton user={user} />
              </FeatureToggle>
              {user?.photoData && user?.photoData?.photo !== '' ? (
                <Avatar
                  imageProps={{ src: user?.photoData?.photo }}
                  size="medium"
                  text={user.name}
                  screenReaderText={user.name}
                  style={{
                    width: '48px',
                    height: '48px',
                    boxShadow: shadowDefaultSize,
                  }}
                  onClick={() => setShowSideMenu(true)}
                />
              ) : (
                <Button
                  data-testid="btn-show-side-menu"
                  type="tertiary"
                  small
                  iconOnly
                  ssrIcon={person}
                  inverseTheme
                  onClick={() => setShowSideMenu(true)}
                />
              )}
            </Flex>
            <Styled.FlexBreak />
          </Flex>
        )}
      </Box>
      {user && <SideMenu show={showSideMenu} onClose={() => setShowSideMenu(false)} />}
      <FeedbackModal opened={showFeedbackModal} onClose={() => setShowFeedbackModal(false)} />
    </>
  )
}

const matchEnvColor = (env: string | undefined): string => {
  switch (env) {
    case 'dev':
      return colourSemanticPositive
    case 'stage':
      // TODO: This should be "Ocean Blue". Up for discussion.
      return '#7AD1DD'
    case 'prod':
      return colourButtonPrimaryEmphasisedPress
    default:
      return colourButtonPrimaryEmphasisedPress
  }
}

export default Header
