import Text from '@ingka/text'
import { List } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CoworkerContentRoleSelection from '../CoworkerContentRoleSelection.component'

const bodySize = 's'

export interface WelcomeModalContentProps {
  index: number
  handleClose: () => void
}

export const CoworkerContents: FC<WelcomeModalContentProps> = ({ index, handleClose }) => {
  const { t } = useTranslation()
  switch (index) {
    case 0:
      return (
        <Text bodySize={bodySize}>{t('introduction.welcome-modal.coworker.step-0.content')}</Text>
      )
    case 1:
      return <ListItem step={index} contentCount={3} />
    case 2:
      return (
        <Text bodySize={bodySize}>{t('introduction.welcome-modal.coworker.step-2.content')}</Text>
      )
    case 3:
      return <ListItem step={index} contentCount={2} />
    case 4:
      return <ListItem step={index} contentCount={4} />
    case 5:
      return (
        <Text bodySize={bodySize}>
          {t('introduction.welcome-modal.coworker.step-5.header')}

          <ListItem step={index} contentCount={2} />
        </Text>
      )
    case 6:
      return <CoworkerContentRoleSelection onRoleSelected={handleClose} />
    default:
      return null
  }
}

interface ListItemProps {
  step: number
  contentCount: number
}

const ListItem: FC<ListItemProps> = ({ step, contentCount }) => {
  const { t } = useTranslation()
  const array = [...Array(contentCount).keys()]
  return (
    <List>
      {array.map((index) => (
        <List.Item>
          <Text bodySize={bodySize}>
            {t(`introduction.welcome-modal.coworker.step-${step}.content-${index}`)}
          </Text>
        </List.Item>
      ))}
    </List>
  )
}
