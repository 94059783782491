import { DateHelper } from 'lib/utils/date.helper'

import { MetricLineChartDataEntry } from './MetricLineChart.types'

export const extrapolateIntermediateDays = (
  data: MetricLineChartDataEntry[],
  startDate: Date,
  endDate: Date
) => {
  const dates = generateDateRange(startDate, endDate)
  const extrapolatedData: MetricLineChartDataEntry[] = []

  dates.forEach((date, index) => {
    const dataOnDate = data.find(
      (IndicatorLineChartDataEntry) => IndicatorLineChartDataEntry.date === date
    )
    if (dataOnDate) {
      extrapolatedData.push(dataOnDate)
    } else if (index !== 0) {
      extrapolatedData.push(extrapolatedData[index - 1])
    }
  })
  return dates
}

const addDaysToDate = (date: Date, daysToAdd: number) => {
  var newDate = new Date(date)
  newDate.setDate(date.getDate() + daysToAdd)
  return newDate
}

const generateDateRange = (startDate: Date, endDate: Date) => {
  var dateArray = []
  var currentDate = startDate
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate))
    currentDate = addDaysToDate(currentDate, 1)
  }
  return dateArray
}

export const convertMetricsToDataset = (
  data: { period: string; value: number }[],
  startDate: Date,
  endDate: Date
) => {
  return (
    data
      ?.filter(
        ({ period }) =>
          new Date(period) >= DateHelper.getStartOfDate(startDate) &&
          new Date(period) <= DateHelper.getEndOfDate(endDate)
      )
      .map((datapoint) => ({
        x: new Date(datapoint.period).getTime(),
        y: datapoint.value,
      }))
      .sort((a, b) => a.x - b.x) ?? []
  )
}
