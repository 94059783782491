import SSRIcon from '@ingka/ssr-icon'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Text from '@ingka/text'
import { space25, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Focus, RepeatContact, SkillData } from 'lib/types/coworkerData.types'

import { colourBrandYellow, colourSemanticCaution } from 'styles/tokens/oldSkapa.tokens'

import EmptyPanel from './components/EmptyPanel.component'
import MainMetric from './components/MainMetric.component'
import RepeatInfoBody from './components/RepeatInfoBody.component'
import RepeatContactGraph from 'components/composites/Graphs/repeatContactGraph.component'
import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import InfoPanel from 'components/composites/Shared/InfoPanel/InfoPanel.component'

import { useSuggestedFocus } from '../useSuggestedFocus.hook'
import SubPanelLayout from './SubPanel.layout'

interface Props {
  data: SkillData
}

const RcSubPanel = ({ data }: Props) => {
  if (!data.repeatContact) {
    return (
      <SubPanelLayout
        leftPanel={<EmptyPanel title={'Repeat contact'} />}
        rightPanel={<EmptyPanel title={'Repeat contact'} />}
      />
    )
  }

  return (
    <SubPanelLayout
      leftPanel={<RcLeftPanel data={data} />}
      rightPanel={<RcRightPanel data={data.repeatContact} />}
    />
  )
}

const RcLeftPanel = ({ data }: { data: SkillData }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const suggestedFocusOpacity = useSuggestedFocus(Focus.RepeatContacts, data)
  const showTopPart =
    !data.repeatContact || !data.repeatContact.repeatR30.enoughVolume || suggestedFocusOpacity === 1

  return (
    <Flex direction={'column'} gap={space100} w={'100%'} justify={'space-evenly'}>
      {showTopPart && (
        <Flex justify={'space-between'}>
          <Text
            headingSize="s"
            style={{
              backgroundColor: colourBrandYellow,
              padding: space25,
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              opacity: suggestedFocusOpacity,
            }}
          >
            {t('features.1-efficiency.highest-potential')}
          </Text>
          {!data?.repeatContact?.repeatR30.enoughVolume && (
            <Text bodySize="s" style={{ textAlign: 'center' }}>
              <SSRIcon
                paths={warningTriangle}
                style={{ marginRight: space25 }}
                color={colourSemanticCaution}
              />
              {t('features.1-efficiency.low-data-volume')}
            </Text>
          )}
        </Flex>
      )}

      <Flex justify={'space-between'}>
        <Flex direction={'column'}>
          <Text headingSize="m">
            {t('features.1-efficiency.your-now', { metric: 'Repeat Contact' })}
          </Text>
          <Text bodySize="s">{t('features.1-efficiency.your-now-subtitle')}</Text>
        </Flex>
        <InfoPanel
          body={<RepeatInfoBody />}
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          handleOpen={() => setShowModal(true)}
        />
      </Flex>

      <Flex style={{ opacity: data?.repeatContact?.repeatR30.enoughVolume ? 1 : 0.5 }}>
        <MainMetric
          value={data?.repeatContact?.repeatR30.repeatShare ?? null}
          avg={data?.repeatContact?.repeatR30.avgRepeatShare?.toFixed(1) ?? 'N/A'}
          top={data?.repeatContact?.repeatR30.minRepeatShare ?? null}
          unit="%"
          color="#FFA6DA"
          topPerformerText={'features.1-efficiency.country-lowest'}
        />
      </Flex>
    </Flex>
  )
}

const RcRightPanel = ({ data }: { data: RepeatContact }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      {data.repeatTrendWeek.length > 0 ? (
        <>
          <Text headingSize="m">
            {t('features.1-efficiency.your-trend', { metric: 'Repeat Contact' })}
          </Text>
          <Text bodySize="s">
            {t('features.1-efficiency.your-trend-subtitle', { metric: 'Repeat Contact' })}
          </Text>

          <Flex w={'100%'} h={300} style={{ marginTop: space100 }}>
            <RepeatContactGraph data={data} />
          </Flex>
        </>
      ) : (
        <EmptyContent translationKey="features.1-efficiency.no-data-available" />
      )}
    </Flex>
  )
}

export default RcSubPanel
