import { useTranslation } from 'react-i18next'

import FormattedCoworkerNote from 'components/features/CoworkerMVP/CoworkerCreateNote/FormattedCoworkerNote.component'
import EfficencyPanel from 'components/features/Efficiency/EfficiencyPanel.component'
import { NumberedHeader } from 'components/primitives/NumberedHeader'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import ReviewEvaluatedInteractions from './ReviewEvaluatedInteractions/ReviewEvaluatedInteractions.component'
import { ReviewMetricsProps } from './ReviewMetrics.types'

const ReviewMetrics: React.FC<ReviewMetricsProps> = ({ coworkerId, coachingSession }) => {
  const { t } = useTranslation()

  return (
    <>
      <NumberedHeader step={1} title={t('features.coaching-session.review.metrics.title')} />

      <EfficencyPanel />
      <ReviewEvaluatedInteractions coworkerId={coworkerId} />
      <FeatureToggle featureKey="coworkerNew">
        <FormattedCoworkerNote coachingSession={coachingSession} />
      </FeatureToggle>
    </>
  )
}

export default ReviewMetrics
