import Tooltip from '@ingka/tooltip'
import { Flex, Skeleton } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetCoworkerCustomerComments } from 'state/slices/api'

import { colourGreyNeutral2, colourGreyNeutral3, spacing100 } from 'styles/tokens/insikt.tokens'

import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import InfoPanel from 'components/composites/Shared/InfoPanel/InfoPanel.component'
import { NumberTag } from 'components/composites/Shared/NumberTag'
import { Widget } from 'components/composites/Shared/Widget'
import { CustomerCommentPreview } from 'components/features/CustomerComments/CustomerCommentPreview'
import { CustomerCommentCTAProps } from 'components/features/CustomerComments/CustomerComments.types'
import FeatureInfoFAQ from 'components/features/FeatureInfoFAQ/FeatureInfoFAQ.component'

const CoworkerCustomerComments: React.FC<CustomerCommentCTAProps> = ({
  coworkerId,
  availableActions,
  onDismiss,
}) => {
  const { t } = useTranslation()
  const { data: coworkerCustomerComments = [], isLoading: coworkerCommentsLoading } =
    useGetCoworkerCustomerComments(coworkerId)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [cardActions, setCardActions] = useState<{ [key: string]: 'dismiss' | null }>({})

  const handleConfirm = async (id: string, action: 'forward' | 'dismiss') => {
    setCardActions((prev) => ({ ...prev, [id]: 'dismiss' }))
    if (action === 'dismiss' && onDismiss) {
      await onDismiss(id)
    }
  }
  if (coworkerCommentsLoading)
    return (
      <Skeleton
        style={{
          minHeight: '50vh',
          minWidth: 'fit-content',
          background: colourGreyNeutral3,
          marginTop: spacing100,
        }}
      />
    )

  return (
    <Widget
      style={{ minHeight: '40vh', background: colourGreyNeutral2, padding: `${spacing100} 0` }}
    >
      <Flex justify={'space-between'}>
        <NumberTag
          count={coworkerCustomerComments.length}
          text={t('features.customer-comments.title')}
          subtitle={t('features.customer-comments.helper-text.coworker-comments')}
        />
        <Tooltip tooltipText={t('Customer comments FAQ')}>
          <InfoPanel
            displayType="button"
            showModal={showModal}
            handleClose={() => setShowModal(false)}
            handleOpen={() => setShowModal(true)}
            body={<FeatureInfoFAQ namespace='features.customer-comments.info-panel' />}
          />
        </Tooltip>
      </Flex>
      {!coworkerCustomerComments || coworkerCustomerComments.length === 0 ? (
        <EmptyContent translationKey={t('features.customer-comments.warnings.no-comments')} />
      ) : (
        <CustomerCommentPreview
          customerComments={coworkerCustomerComments}
          availableActions={availableActions}
          cardActions={cardActions}
          onConfirm={handleConfirm}
        />
      )}
    </Widget>
  )
}

export default CoworkerCustomerComments
