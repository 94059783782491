export const firebaseConfigCypress = {
  apiKey: 'AIzaSyCl7lptLiWdyf58jKIcIdGLy9GGs84DLDU',
  authDomain: 'ingka-csc-tt-dev.firebaseapp.com',
  projectId: 'ingka-csc-tt-dev',
  storageBucket: 'ingka-csc-tt-dev.appspot.com',
  messagingSenderId: '914535467643',
  appId: '1:914535467643:web:702c7a6f9a5b255997f0de',
}

export const firebaseConfigDev = {
  apiKey: 'AIzaSyCl7lptLiWdyf58jKIcIdGLy9GGs84DLDU',
  authDomain: 'dev.insikt.ingka.com',
  projectId: 'ingka-csc-tt-dev',
  storageBucket: 'ingka-csc-tt-dev.appspot.com',
  messagingSenderId: '914535467643',
  appId: '1:914535467643:web:702c7a6f9a5b255997f0de',
  measurementId: 'G-F1Q5JFMTNX',
}

export const firebaseConfigPreProd = {
  apiKey: 'AIzaSyCl7lptLiWdyf58jKIcIdGLy9GGs84DLDU',
  authDomain: 'dev.insikt.ingka.com', // dev.insikt.ingka.com
  projectId: 'ingka-csc-tt-dev',
  storageBucket: 'ingka-csc-tt-dev.appspot.com',
  messagingSenderId: '914535467643',
  appId: '1:914535467643:web:515685fd9596cd9297f0de',
  measurementId: 'G-3DN8M3MZPX',
}

export const firebaseConfigProd = {
  apiKey: 'AIzaSyDk9-ey_CHhDqkv6RQ3rk75ZmdI3-pR3EQ',
  authDomain: 'insikt.ingka.com',
  projectId: 'ingka-csc-tt-prod',
  storageBucket: 'ingka-csc-tt-prod.appspot.com',
  messagingSenderId: '532676450400',
  appId: '1:532676450400:web:f82e520ff12fbb68c90b05',
  measurementId: 'G-KQLK6PKBFX',
}

export const firebaseConfigProdV2 = {
  apiKey: 'AIzaSyC9lVBBI2vqfqiff9c0N6Rz4DNB46sNIF8',
  authDomain: 'v2.insikt.ingka.com',
  projectId: 'ingka-csi-insikt-prod',
  storageBucket: 'ingka-csi-insikt-prod.appspot.com',
  messagingSenderId: '792948040284',
  appId: '1:792948040284:web:83f1bec1888840fbcb3c99',
  measurementId: 'G-HPY5R93PLY',
}

export const firebaseConfigStage = {
  apiKey: 'AIzaSyBWpNtq7DLzh6qBf0leBl_Svepyi4_ZuBc',
  authDomain: 'stage.insikt.ingka.com',
  projectId: 'ingka-csi-insikt-stage',
  storageBucket: 'ingka-csi-insikt-stage.appspot.com',
  messagingSenderId: '583847896230',
  appId: '1:583847896230:web:ab11319653759123b4c067',
  measurementId: 'G-9W7B2HD0GC',
}
