import Tooltip from '@ingka/tooltip'

import { ConditionalTooltipProps } from './ConditionalTooltip.types'

/**
 * Extension of the Tooltip component that only renders a Tooltip if a condition is met, otherwise rendering the children by themselves.
 */
const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
  condition,
  children,
  ...otherProps
}) => {
  if (condition) {
    return <Tooltip {...otherProps}>{children}</Tooltip>
  }
  return <>{children}</>
}
export default ConditionalTooltip
