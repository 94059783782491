import { Department, Focus, SkillData } from 'lib/types/coworkerData.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useCountrySettings } from 'state/slices/api/api.hooks'

export const useSuggestedFocus = (skillName: Focus, skillData: SkillData) => {
  const coworkerId = useCoworkerId()
  const { data: settings } = useCountrySettings(coworkerId)

  if (!settings) {
    return 0
  }

  if (skillData.department === Department.Sales) {
    if (!settings.suggestSalesFocus) {
      return 0
    }
    if (skillData.salesRoleRecommendation?.recommendation === skillName) {
      return 1
    }

    return 0
  }

  if (skillData.department === Department.Resolution) {
    if (!settings.suggestResolutionFocus) {
      return 0
    }
    if (skillData.resolutionRecommendation?.recommendation === skillName) {
      return 1
    }
    return 0
  }
}
