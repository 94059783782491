import { Box, Flex } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { spacing100 } from 'styles/tokens/insikt.tokens'

import { SubTitleBlue } from 'components/primitives/Text/Text.component'

import { CoworkerContents, WelcomeModalContentProps } from './Contents/CoworkerContents.component'
import { COWORKER_CONTENT_COUNT } from './WelcomeModal.component'

const width = '350px'

export const CoworkerWelcomeModal: FC<WelcomeModalContentProps> = ({ index, handleClose }) => {
  const { t } = useTranslation()
  return (
    <Flex align={'center'} direction="column" style={{ width: '450px' }}>
      <SubTitleBlue style={{ textAlign: 'center' }}>
        {t(`introduction.welcome-modal.coworker.step-${index}.title`)}
      </SubTitleBlue>
      {index !== COWORKER_CONTENT_COUNT - 1 && (
        <img
          alt="Feature visualisation"
          style={{
            width: width,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: spacing100,
            display: 'block',
          }}
          src={`/images/welcomeModals/coworker-${index}.png`}
        />
      )}
      <Box style={{ backgroundColor: '#F1F4FE', width: '100%', padding: spacing100 }}>
        <CoworkerContents index={index} handleClose={handleClose} />
      </Box>
    </Flex>
  )
}
